import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

// Define the initial state for auth
interface AuthState {
  user: string | null;
  idToken: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  loading: boolean;
  error: string | null;
  scouts?: any[];
}

const initialState: AuthState = {
  user: null,
  idToken: null,
  accessToken: null,
  refreshToken: null,
  loading: false,
  error: null,
};

const baseUrl = `${process.env.REACT_APP_BASE_URL}/api/auth`;

// Async thunk for sign-in
export const signIn = createAsyncThunk(
  "auth/signIn",
  async (
    { email, password }: { email: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(`${baseUrl}/signin`, {
        email,
        password,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Sign-in failed");
    }
  }
);

export const signOut = createAsyncThunk(
  "auth/signOut",
  async ({ accessToken }: { accessToken: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${baseUrl}/signout`, {
        accessToken,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Sign-out failed"
      );
    }
  }
);

export const fetchUserInfo = createAsyncThunk(
  "auth/fetchUserInfo",
  async ({ accessToken }: { accessToken: string }, { rejectWithValue }) => {
    try {
      console.log("fetchUserInfo");
      const response = await axios.post(`${baseUrl}/fetch-user-info`, {
        accessToken,
      });
      console.log(response);
      return response.data; // Assuming user info is in response.data
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Fetching user info failed"
      );
    }
  }
);
interface userInformation {
  firstName: string;
  initial?: string;
  lastName: string;
  phone: string;
  email: string;
  confirmEmail?: string;
  password: string;
  confirmPassword?: string;
  role: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state?: string;
  zipCode: string;
  dob: Date | null;
  gender?: string;
}
export const saveScout = createAsyncThunk(
  "auth/saveScout",
  async (
    { userInformation }: { userInformation: userInformation },
    { rejectWithValue }
  ) => {
    try {
      console.log("saveScout action triggered");
      const response = await axios.post(`${baseUrl}/save-scout`, {
        userInformation,
      });
      console.log("Response from saveScout API:", response.data);
      return response.data; // Assuming the API returns the saved user information
    } catch (error: any) {
      console.error("Error in saveScout:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to save scout information"
      );
    }
  }
);

export const getScouts = createAsyncThunk(
  "auth/getScouts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/get-scouts`);
      return response.data.scouts; // Assuming `scouts` is the array in the API response
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to retrieve scouts"
      );
    }
  }
);

export const denyScout = createAsyncThunk(
  "auth/denyScout",
  async (
    {
      email,
      managerNotes,
      approverManager,
    }: { email: string; managerNotes: string; approverManager: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(`${baseUrl}/deny-scout`, {
        email,
        managerNotes,
        approverManager,
      });
      return response.data; // Assuming the API returns a success message
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to deny scout"
      );
    }
  }
);

export const acceptScout = createAsyncThunk(
  "auth/acceptScout",
  async (
    { email, approverManager }: { email: string; approverManager: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(`${baseUrl}/accept-scout`, {
        email,
        approverManager,
      });
      return response.data; // Assuming the API returns a success message or relevant data
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to accept scout"
      );
    }
  }
);

export const sendGuardianFreeEmail = createAsyncThunk(
  "auth/sendGuardianFreeEmail",
  async (
    { email, firstName }: { email: string; firstName: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(`${baseUrl}/send-guardian-free`, {
        email,
        firstName,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to send email"
      );
    }
  }
);

export const sendGuardianPaidEmail = createAsyncThunk(
  "auth/sendGuardianPaidEmail",
  async (
    { email, firstName }: { email: string; firstName: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(`${baseUrl}/send-guardian-paid`, {
        email,
        firstName,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to send email"
      );
    }
  }
);

export const sendScoutEmail = createAsyncThunk(
  "auth/sendScoutEmail",
  async (
    { email, firstName }: { email: string; firstName: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(`${baseUrl}/send-scout`, {
        email,
        firstName,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to send email"
      );
    }
  }
);

// Create the auth slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.idToken = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.email; // You can set the user email from the response if needed
        state.idToken = action.payload.idToken;
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
        state.error = null;
      })
      .addCase(signIn.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(signOut.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.user = null;
        state.idToken = null;
        state.accessToken = null;
        state.refreshToken = null;
        state.error = null;

        // Remove user and token from localStorage
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
      })
      .addCase(signOut.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(saveScout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveScout.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.userInformation; // Update based on the API response
        state.error = null;
      })
      .addCase(saveScout.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(sendGuardianFreeEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendGuardianFreeEmail.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(
        sendGuardianFreeEmail.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(sendGuardianPaidEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendGuardianPaidEmail.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(
        sendGuardianPaidEmail.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(fetchUserInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        console.log(action.payload);
        state.loading = false;
        state.user = action.payload.userData; // Adjust this based on your API response
        state.error = null;
      })
      .addCase(fetchUserInfo.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getScouts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getScouts.fulfilled, (state, action) => {
        state.loading = false;
        state.scouts = action.payload; // Store the scouts in the state
        state.error = null;
      })
      .addCase(getScouts.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(denyScout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(denyScout.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        // Optional: Remove the denied scout from the `scouts` state, so it doesn't appear in the UI
        // state.scouts = state.scouts?.filter(
        //   (scout) => scout.email !== action.meta.arg.email
        // );
      })
      .addCase(denyScout.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(acceptScout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(acceptScout.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        // Optionally, update the scout's status to accepted in the state
        // state.scouts = state.scouts?.map((scout) =>
        //   scout.email === action.meta.arg.email
        //     ? { ...scout, verdict: true }
        //     : scout
        // );
      })
      .addCase(acceptScout.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
