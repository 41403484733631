import React, { useState } from "react";

import angleLeft1 from "./NewUser/angle-left-1.svg";
import angleSmallLeft1 from "./NewUser/angle-small-left-1.svg";
import bell1 from "./NewUser/bell-1.svg";
import ellipse1 from "./NewUser/ellipse-1.svg";
import eye1 from "./NewUser/eye-1.svg";
import eyeCrossed1 from "./NewUser/eye-crossed-1.svg";
import frame from "./NewUser/frame.svg";
import image1 from "./NewUser/image-1.svg";
import "./NewUser.css";
import twemojiFlagPuertoRico2 from "./NewUser/twemoji-flag-puerto-rico-2.svg";
import twemojiFlagPuertoRico from "./NewUser/twemoji-flag-puerto-rico.svg";

interface NewUserProps {
  newUser: boolean;
  setNewUser: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NewUserCreate = ({
  newUser,
  setNewUser,
}: NewUserProps): JSX.Element => {
  return (
    <div className="new-user-create">
      <div className="container">
        {/* <header className="header">
          <div className="header-left">
            <div className="back-button">
              <img className="angle-left" alt="Back" src={image1} />
            </div>
            <div className="page-title">Accounts</div>
          </div>
          <div className="header-right">
            <div className="notification">
              <img className="bell" alt="Notifications" src={bell1} />
              <div className="notification-count">5</div>
            </div>
            <div className="user-info">
              <img className="user-avatar" alt="User" src={ellipse1} />
              <div className="user-details">
                <div className="user-name">Allie Gretter</div>
                <div className="user-role">Manager</div>
              </div>
              <img className="dropdown-icon" alt="Dropdown" src={angleLeft1} />
            </div>
          </div>
        </header> */}

        <div className="form-container">
          <h1 className="form-title">New User</h1>
          <form className="user-form">
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="firstName">First Name*</label>
                <input type="text" id="firstName" placeholder="First name" />
              </div>
              <div className="form-group">
                <label htmlFor="initial">Initial*</label>
                <input type="text" id="initial" placeholder="Initial" />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Last Name*</label>
                <input type="text" id="lastName" placeholder="Last name" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="gender">Gender*</label>
                <div className="select-wrapper">
                  <select id="gender">
                    <option>Male</option>
                    <option>Female</option>
                    <option>Other</option>
                  </select>
                  <img
                    className="dropdown-icon"
                    alt="Dropdown"
                    src={angleSmallLeft1}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="mobileNumber">Mobile Number*</label>
                <div className="input-group">
                  <span className="country-code">+1</span>
                  <img
                    className="flag"
                    alt="Flag"
                    src={twemojiFlagPuertoRico}
                  />
                  <img
                    className="flag"
                    alt="Flag"
                    src={twemojiFlagPuertoRico2}
                  />
                  <input
                    type="text"
                    id="mobileNumber"
                    placeholder="(787) 000 0000"
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="dob">Date Of Birth*</label>
                <div className="input-group">
                  <input type="text" id="dob" placeholder="08-05-1998" />
                  <img className="calendar-icon" alt="Calendar" src={frame} />
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="email">Email*</label>
                <input
                  type="email"
                  id="email"
                  placeholder="Example1212@gmail.com"
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirmEmail">Confirm Email*</label>
                <input
                  type="email"
                  id="confirmEmail"
                  placeholder="Example1212@gmail.com"
                />
              </div>
              <div className="form-group">
                <label htmlFor="role">Role*</label>
                <div className="select-wrapper">
                  <select id="role">
                    <option>Manager</option>
                    <option>Admin</option>
                    <option>User</option>
                  </select>
                  <img
                    className="dropdown-icon"
                    alt="Dropdown"
                    src={angleSmallLeft1}
                  />
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="zipCode">Zip Code*</label>
                <input type="text" id="zipCode" placeholder="000000" />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password*</label>
                <div className="input-group">
                  <input
                    type="password"
                    id="password"
                    placeholder="Admin@123$"
                  />
                  <img className="eye-icon" alt="Show Password" src={eye1} />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">Confirm Password*</label>
                <div className="input-group">
                  <input
                    type="password"
                    id="confirmPassword"
                    placeholder="Admin@123$"
                  />
                  <img
                    className="eye-icon"
                    alt="Hide Password"
                    src={eyeCrossed1}
                  />
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="addressLine1">Address Line 1*</label>
                <input
                  type="text"
                  id="addressLine1"
                  placeholder="House no., Building, Street"
                />
              </div>
              <div className="form-group">
                <label htmlFor="addressLine2">Address Line 2*</label>
                <input type="text" id="addressLine2" placeholder="Landmark" />
              </div>
              <div className="form-group">
                <label htmlFor="city">City*</label>
                <div className="select-wrapper">
                  <select id="city">
                    <option>City</option>
                  </select>
                  <img
                    className="dropdown-icon"
                    alt="Dropdown"
                    src={angleSmallLeft1}
                  />
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="state">State*</label>
                <div className="select-wrapper">
                  <select id="state">
                    <option>State</option>
                  </select>
                  <img
                    className="dropdown-icon"
                    alt="Dropdown"
                    src={angleSmallLeft1}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="country">Country*</label>
                <div className="select-wrapper">
                  <select id="country">
                    <option>Country</option>
                  </select>
                  <img
                    className="dropdown-icon"
                    alt="Dropdown"
                    src={angleSmallLeft1}
                  />
                </div>
              </div>
            </div>

            <div className="form-actions">
              <button
                type="button"
                className="btn-back"
                onClick={() => {
                  setNewUser(!newUser);
                }}
              >
                Back
              </button>
              <button type="submit" className="btn-create">
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
