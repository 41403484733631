// src/App.tsx
import React from "react";
import { AuthProvider } from "./context/AuthContext";
import AppRoutes from "./routes";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./utils/theme";
import { PlayerFormProvider } from "./context/PlayerFormContext"; // Importar el proveedor

const App: React.FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <PlayerFormProvider> {/* Envolver AppRoutes con PlayerFormProvider */}
          <AppRoutes />
        </PlayerFormProvider>
      </AuthProvider>
    </ChakraProvider>
  );
};

export default App;
