import React from "react";
import { Container, Heading, Button, Flex, Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Flex height="100vh" alignItems="center" justifyContent="center">
      <Box textAlign="center">
        <Heading mb={4}>Home Page</Heading>
        <Button
          data-testid="login-page-button"
          type="submit"
          colorScheme="blue"
          onClick={() => navigate("/")}
        >
          Login Page
        </Button>
      </Box>
    </Flex>
  );
};

export default HomePage;
