// src/pages/CreatePlayerStep2.tsx

import React, { useContext } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Stack,
  Text,
  List,
  ListItem,
  Icon,
  Image,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa';
import { PlayerFormContext } from '../context/PlayerFormContext';

// Importar el componente WizardButton
import WizardButton from '../components/Shared/WizardButton';

interface CreatePlayerStep2Props {
  handleNextStep: () => void;
  handlePreviousStep: () => void;
}

const CreatePlayerStep2: React.FC<CreatePlayerStep2Props> = ({
  handleNextStep,
  handlePreviousStep,
}) => {
  const { formData, setFormData } = useContext(PlayerFormContext)!;

  // Definir el esquema de validación con Yup
  const validationSchema = Yup.object({
    selectedPlan: Yup.string()
      .oneOf(['insurance', 'noInsurance'], 'Debes seleccionar una opción')
      .required('La selección es obligatoria'),
  });

  // Configurar Formik
  const formik = useFormik({
    initialValues: {
      selectedPlan: formData.selectedPlan || '', // Inicializar como cadena vacía si no está definido
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Actualizar el estado global del formulario
      setFormData({ ...formData, selectedPlan: values.selectedPlan });
      // Avanzar al siguiente paso
      handleNextStep();
    },
  });

  const handleBack = () => {  
    formik.setTouched({})
    handlePreviousStep()
  }

  return (
    <Box bg="white" p={6} borderRadius="md" boxShadow="md" maxW="100%" mx="auto">
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={6}>
          {/* Selección del Insurance Plan */}
          <FormControl
            isInvalid={!!formik.errors.selectedPlan && formik.touched.selectedPlan}
          >
            <Flex
              direction={{ base: 'column', md: 'row' }}
              gap={6}
              justify="center"
              align="stretch" // Alineación para estirar ambas tarjetas
            >
              {/* Tarjeta para Aceptar el Insurance Plan */}
              <Flex
                direction="column"
                align="center"
                p={4}
                borderWidth="1px"
                borderRadius="md"
                cursor="pointer"
                bg={formik.values.selectedPlan === 'insurance' ? 'gray.100' : 'white'}
                boxShadow={formik.values.selectedPlan === 'insurance' ? 'lg' : 'md'}
                onClick={() => formik.setFieldValue('selectedPlan', 'insurance')}
                position="relative"
                width={{ base: '100%', md: '300px' }} // Ancho fijo de 300px en pantallas medianas y superiores
                height={{ base: 'auto', md: '400px' }} // Altura fija de 400px en pantallas medianas y superiores
                transition="all 0.2s"
                overflow="hidden" // Evita que el contenido se desborde
              >
                {/* Indicador de Selección */}
                <Icon
                  as={formik.values.selectedPlan === 'insurance' ? FaCheckCircle : FaRegCircle}
                  color={formik.values.selectedPlan === 'insurance' ? 'blue.900' : 'gray.300'}
                  boxSize={6}
                  position="absolute"
                  top="2"
                  right="2"
                />

                {/* Logo de la Insurance Company */}
                <Flex justify="center" align="center" width="100%" height="100%">
                  <Image
                  src="/images/Multinational_Logo_2022.png"
                  alt="Insurance Company Logo"
                  objectFit="contain"
                  width="90%"
                  height="90%"
                  />
                </Flex>
                <Heading as="h3" size="md" mb={2} textAlign="center">
                  Insurance Plan
                </Heading>
                <Text mb={4} textAlign="center" fontSize="sm">
                  Can insure player for the following:
                </Text>
                <List spacing={1} textAlign="left" mb={4} fontSize="sm">
                  <ListItem>- Career Ending Injuries</ListItem>
                  <ListItem>- Weather Calamities</ListItem>
                  <ListItem>- Loss of Parent</ListItem>
                  <ListItem>- Stolen Equipment</ListItem>
                  <ListItem>- Reimbursement Of Sport Trips</ListItem>
                </List>
                <Text fontWeight="bold" fontSize="md">
                  $250.00 per year
                </Text>
              </Flex>

              {/* Tarjeta para No Aceptar el Insurance Plan */}
              <Flex
                direction="column"
                align="center"
                p={4}
                borderWidth="1px"
                borderRadius="md"
                cursor="pointer"
                bg={formik.values.selectedPlan === 'noInsurance' ? 'gray.100' : 'white'}
                boxShadow={formik.values.selectedPlan === 'noInsurance' ? 'lg' : 'md'}
                onClick={() => formik.setFieldValue('selectedPlan', 'noInsurance')}
                position="relative"
                width={{ base: '100%', md: '300px' }} // Ancho fijo de 300px en pantallas medianas y superiores
                height={{ base: 'auto', md: '400px' }} // Altura fija de 400px en pantallas medianas y superiores
                transition="all 0.2s"
                overflow="hidden" // Evita que el contenido se desborde
              >
                {/* Indicador de Selección */}
                <Icon
                  as={formik.values.selectedPlan === 'noInsurance' ? FaCheckCircle : FaRegCircle}
                  color={formik.values.selectedPlan === 'noInsurance' ? 'blue.900' : 'gray.300'}
                  boxSize={6}
                  position="absolute"
                  top="2"
                  right="2"
                />

                <Heading as="h3" size="md" mb={2} textAlign="center">
                  No Insurance Plan
                </Heading>
                <Text
                  textAlign="center"
                  mb={4}
                  fontSize="sm"
                  noOfLines={2} // Limita a 2 líneas y trunca con puntos suspensivos si excede
                  maxWidth="100%" // Asegura que el truncamiento funcione correctamente
                >
                  I choose not to add the insurance plan to my player profile. This is a longer text to demonstrate how truncation works when the content exceeds the allotted space within the card.
                </Text>
                <Text fontWeight="bold" fontSize="md" mt="auto">
                  Free
                </Text>
              </Flex>
            </Flex>
            <FormErrorMessage>{formik.errors.selectedPlan}</FormErrorMessage>
          </FormControl>
        </Stack>

        {/* Botones de Navegación */}
        <Flex justify="flex-end" mt={8} gap={4}>
          <WizardButton
            onClick={handleBack}
            variant="back"
            isLoading={formik.isSubmitting}
          >
            Back
          </WizardButton>
          <WizardButton
            type="submit"
            variant="next"
            isDisabled={!formik.values.selectedPlan}
            isLoading={formik.isSubmitting}
          >
            Next
          </WizardButton>
        </Flex>
      </form>
    </Box>
  );
};

export default CreatePlayerStep2;
