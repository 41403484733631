import React, { useState } from "react";
import { UserList } from "../components/common/UserList";
import { NewUserCreate } from "../components/common/NewUser";

const Accounts: React.FC = () => {
  const [newUser, setNewUser] = useState(false);

  return (
    <div>
      {newUser ? (
        <NewUserCreate newUser={newUser} setNewUser={setNewUser} />
      ) : (
        <UserList newUser={newUser} setNewUser={setNewUser} />
      )}
    </div>
  );
};

export default Accounts;
