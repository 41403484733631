import React, { createContext, useState } from 'react';

interface FormValues {
  firstName: string;
  initial: string;
  maidenName: string;
  lastName: string;
  dateOfBirth: string;
  playingPositions: string[];
  uniformNumber: string;
  city: string;
  state: string;
  country: string;
  graduationYear: string;
  graduationYearOption: string;
  currentSchool: string;
  aimingColleges: string[];
  last4SN: string;
  weight: string;
  height: string;
  throwingHand: string;
  battingHand: string;
  legalWaiver: boolean;
  selectedPlan: string;
}

interface PlayerFormContextProps {
  formData: FormValues;
  setFormData: React.Dispatch<React.SetStateAction<FormValues>>;
}

// Valor predeterminado para formData
const defaultFormData: FormValues = {
  firstName: '',
  initial: '',
  maidenName: '',
  lastName: '',
  dateOfBirth: '',
  last4SN: '',
  playingPositions: [],
  uniformNumber: '',
  city: '',
  state: '',
  country: '',
  graduationYear: '',
  graduationYearOption: '',
  currentSchool: '',
  aimingColleges: [],
  weight: '',
  height: '',
  throwingHand: '',
  battingHand: '',
  legalWaiver: false,
  selectedPlan: '',
};

// Crear el contexto sin posibilidad de null
export const PlayerFormContext = createContext<PlayerFormContextProps>({
  formData: defaultFormData,
  setFormData: () => {},
});

export const PlayerFormProvider = ({ children }: { children: React.ReactNode }) => {
  const [formData, setFormData] = useState<FormValues>(defaultFormData);

  return (
    <PlayerFormContext.Provider value={{ formData, setFormData }}>
      {children}
    </PlayerFormContext.Provider>
  );
};