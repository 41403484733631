import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  IconButton,
  VStack,
  Text,
  useColorModeValue,
  Icon,
  IconProps,
  ComponentWithAs,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useLocation, useNavigate } from "react-router-dom";
import { getScouts, signOut } from "../../store/authSlice";
import {
  GroupUserIcon,
  GroupUserIconFill,
  LeagueICon,
  LeagueIConFill,
  LeftArrow,
  Logo,
  LogoIcon,
  LogoutIcon,
  NewsICon,
  NewsIConFill,
  NewsIConFilledBlue,
  RightArrow,
  SettingIcon,
  SettingIconFill,
  TennisRacketIcon,
  TennisRacketIconFill,
  TshirtIcon,
  TshirtIconFill,
  UserIcon,
  UserIconFill,
} from "../../assets/SVGs/Global";

export default function SideNav({
  isCollapsed,
  toggleSidebar,
  isMobile,
}: {
  isCollapsed: boolean;
  toggleSidebar: () => void;
  isMobile: boolean;
}) {
  const { scouts } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [pendingScoutCount, setPendingScoutCount] = useState(0);
  const dispatch = useDispatch<AppDispatch>();
  const bg = useColorModeValue("#ffffff", "black");

  const signOutHandler = async () => {
    const accessToken = localStorage.getItem("accessToken");
    let resultAction;
    if (accessToken) {
      resultAction = await dispatch(signOut({ accessToken }));
    } else {
      console.error("Access token is null");
    }

    if (resultAction?.meta.requestStatus === "fulfilled") {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      navigate("/");
    }
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      dispatch(getScouts());
    }
  }, []);

  useEffect(() => {
    // Transform the retrieved scouts data into the structure expected by your table.
    const transformedData = scouts
      ?.filter((scout) => {
        // If `isRequestView` is true, only include scouts with `VERDICT` of `null` (i.e., pending).
        // If `isRequestView` is false, include all scouts.

        return scout.VERDICT === null;
      })
      .map((scout) => ({
        id: scout.SCOUT_REQUEST_ID,
        createdDate: new Date(scout.CREATED_DATE).toLocaleDateString(),
        createdTime: new Date(scout.CREATED_DATE).toLocaleTimeString(),
        name: scout.NAME,
        telephoneNumber: scout.PHONE_NUMBER,
        Email: scout.EMAIL,
        Gender: scout.GENDER,
        BirthDate: new Date(scout.BIRTHDATE).toLocaleDateString(),
        Address: scout.ADDRESS,
        status:
          scout.VERDICT === null
            ? "Pending"
            : scout.VERDICT
            ? "Approved"
            : "Denied",
      }));

    setPendingScoutCount(transformedData?.length || 0);
  }, [scouts]);

  return (
    <Flex
      as="nav"
      pos="fixed"
      top="0"
      left="0"
      width={isCollapsed ? "16" : "270px"}
      h="100vh"
      zIndex="2"
      transition="width 0.3s ease-in-out"
      flexDirection="column"
      position="relative"
    >
      {!isMobile && (
        <IconButton
          aria-label="Toggle Sidebar"
          icon={isCollapsed ? <RightArrow /> : <LeftArrow />}
          onClick={toggleSidebar}
          variant="ghost"
          position="absolute"
          top="20px"
          bg={bg}
          borderRightRadius="full"
          boxShadow="md"
          right="-35px"
          mx={isCollapsed ? "auto" : "0"}
          _hover={{ bg: "white", opacity: 0.8 }}
        />
      )}
      <Flex direction={"column"} zIndex="3" bg={bg} boxShadow="md" h={"100%"}>
        {/* Header */}
        <Flex
          p={6}
          justifyContent={isCollapsed ? "center" : "space-between"}
          mb={isCollapsed ? { base: 0, md: 4 } : 0}
          alignItems="center"
        >
          {isCollapsed ? (
            <Box
              width={35}
              height={35}
              bg="primary.500"
              rounded="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mx="auto"
            >
              <LogoIcon height={35} width={35} />
            </Box>
          ) : (
            <Box
              bg="primary.500"
              rounded="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mx="auto"
            >
              <Logo width={60} height={60} />
            </Box>
          )}
        </Flex>

        {/* Main content */}
        <VStack
          spacing={4}
          alignItems={isCollapsed ? "center" : "flex-start"}
          px={isCollapsed ? 2 : 4}
          flex={1}
          py={{ base: 2, md: 4 }}
          overflow="auto"
        >
          <NavItem
            icon={NewsIConFill}
            outlineIcon={NewsICon}
            selectedIcon={NewsIConFilledBlue}
            isSelected={pathname === "/news"}
            label="News"
            isCollapsed={isCollapsed}
            onClick={() => navigate("/news")}
            notificationCount={null}
          />
          <NavItem
            icon={LeagueIConFill}
            outlineIcon={LeagueICon}
            isSelected={pathname === "/league"}
            label="League"
            isCollapsed={isCollapsed}
            onClick={() => navigate("/league")}
            notificationCount={null}
          />
          <NavItem
            isSelected={pathname === "/team"}
            outlineIcon={TshirtIcon}
            icon={TshirtIconFill}
            label="Team"
            notificationCount={null}
            isCollapsed={isCollapsed}
            onClick={() => navigate("/team")}
          />
          <NavItem
            isSelected={pathname === "/players"}
            onClick={() => navigate("/players")}
            icon={TennisRacketIconFill}
            outlineIcon={TennisRacketIcon}
            notificationCount={null}
            label="Players"
            isCollapsed={isCollapsed}
          />
          <NavItem
            onClick={() => navigate("/accounts")}
            isSelected={pathname === "/accounts"}
            outlineIcon={GroupUserIcon}
            notificationCount={null}
            icon={GroupUserIconFill}
            label="Accounts"
            isCollapsed={isCollapsed}
          />
          <NavItem
            onClick={() => navigate("/scout-access")}
            icon={UserIconFill}
            isSelected={pathname === "/scout-access"}
            outlineIcon={UserIcon}
            notificationCount={pendingScoutCount}
            label="Scout Access"
            isCollapsed={isCollapsed}
          />
          <NavItem
            onClick={() => navigate("/settings")}
            icon={SettingIconFill}
            outlineIcon={SettingIcon}
            notificationCount={null}
            isSelected={pathname === "/settings"}
            label="Settings"
            isCollapsed={isCollapsed}
          />

          {/* Footer */}
          <Flex
            onClick={signOutHandler}
            marginTop={"auto"}
            w={isCollapsed ? "43px" : "100%"}
            fontWeight={"semibold"}
            justify={isCollapsed ? "center" : "space-between"}
            alignItems={"center"}
            px={isCollapsed ? 3 : 4}
            py={isCollapsed ? 3 : 2}
            cursor={"pointer"}
            borderRadius={"lg"}
            _hover={{ bg: "#f2f2f2" }}
          >
            <Box>{!isCollapsed && "Log out"}</Box>
            <LogoutIcon />
          </Flex>
        </VStack>
      </Flex>
    </Flex>
  );
}

interface NavItemProps {
  icon: ComponentWithAs<"svg", IconProps> | IconType;
  outlineIcon: ComponentWithAs<"svg", IconProps> | IconType;
  selectedIcon?: ComponentWithAs<"svg", IconProps> | IconType;
  label: string;
  notificationCount: number | string | null;
  isCollapsed: boolean;
  isSelected: boolean;
  onClick?: () => void;
}

const NavItem = ({
  icon,
  label,
  isCollapsed,
  outlineIcon,
  onClick,
  notificationCount,
  isSelected,
  selectedIcon,
}: NavItemProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Flex
      align="center"
      justifyContent={"space-between"}
      px={isCollapsed ? 3 : 4}
      py={isCollapsed ? 3 : 2}
      borderRadius="lg"
      role="group"
      cursor="pointer"
      w={isCollapsed ? "auto" : "100%"}
      onClick={onClick}
      borderWidth="1px"
      color={isSelected ? (isCollapsed ? "white" : "#232327") : "#797987"}
      bgColor={
        isSelected ? (isCollapsed ? "#1B154B" : "#f2f2f2") : "transparent"
      }
      fontWeight="semibold"
      borderColor={isSelected ? "#1B154B" : "transparent"}
      _hover={isCollapsed ? { bg: "#1B154B" } : { bg: "#f2f2f2" }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Flex
        align="center"
        gap={3}
        sx={
          isCollapsed
            ? isSelected
              ? {
                  path: { fill: "white" },
                  rect: { stroke: "white" },
                }
              : {
                  _groupHover: {
                    path: { fill: "white" },
                    rect: {
                      fill: "#1B154B",
                      stroke: "white",
                    },
                  },
                }
            : isSelected
            ? {
                path: { fill: "#1B154B" },
              }
            : {
                path: { fill: "#797987" },
              }
        }
      >
        {icon && (
          <Icon
            height="auto"
            width="auto"
            fontSize="16"
            as={
              isHovered || isSelected
                ? isSelected && selectedIcon
                  ? selectedIcon
                  : icon
                : outlineIcon
            }
          />
        )}
        {!isCollapsed && <Text fontSize="md">{label}</Text>}
      </Flex>
      {isCollapsed
        ? notificationCount && (
            <Flex
              bg={"#F05252"}
              h={1}
              w={1}
              mr={-1}
              borderRadius={"full"}
            ></Flex>
          )
        : notificationCount && (
            <Flex
              bg={"#FFD8D8"}
              h={6}
              borderRadius={"full"}
              color={"#F05252"}
              justifyContent={"center"}
              alignItems={"center"}
              px={3}
            >
              {notificationCount}
            </Flex>
          )}
    </Flex>
  );
};
