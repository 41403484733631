import { LanguageData } from "../types/pages/registration";
import USFlag from "../assets/flags/us.png";
import FRFlag from "../assets/flags/fr.png";
import NLFlag from "../assets/flags/nl.png";
import ESFlag from "../assets/flags/es.png";

export const languages: LanguageData[] = [
  {
    label: "English",
    icon: USFlag,
    value: "en",
  },
  {
    label: "French",
    icon: FRFlag,
    value: "fr",
  },
  {
    label: "Dutch",
    icon: NLFlag,
    value: "nl",
  },
  {
    label: "Spanish",
    icon: ESFlag,
    value: "en",
  },
];

export const states = [
  { label: "Puerto Rico", value: "puerto rico" },
  { label: "Alabama", value: "alabama" },
  { label: "Alaska", value: "alaska" },
  { label: "Arizona", value: "arizona" },
  { label: "Arkansas", value: "arkansas" },
  { label: "California", value: "california" },
  { label: "Colorado", value: "colorado" },
  { label: "Connecticut", value: "connecticut" },
  { label: "Delaware", value: "delaware" },
  { label: "District of Columbia", value: "district of columbia" },
  { label: "Florida", value: "florida" },
  { label: "Georgia", value: "georgia" },
  { label: "Hawaii", value: "hawaii" },
  { label: "Idaho", value: "idaho" },
  { label: "Illinois", value: "illinois" },
  { label: "Indiana", value: "indiana" },
  { label: "Iowa", value: "iowa" },
  { label: "Kansas", value: "kansas" },
  { label: "Kentucky", value: "kentucky" },
  { label: "Louisiana", value: "louisiana" },
  { label: "Maine", value: "maine" },
  { label: "Maryland", value: "maryland" },
  { label: "Massachusetts", value: "massachusetts" },
  { label: "Michigan", value: "michigan" },
  { label: "Minnesota", value: "minnesota" },
  { label: "Mississippi", value: "mississippi" },
  { label: "Missouri", value: "missouri" },
  { label: "Montana", value: "montana" },
  { label: "Nebraska", value: "nebraska" },
  { label: "Nevada", value: "nevada" },
  { label: "New Hampshire", value: "new hampshire" },
  { label: "New Jersey", value: "new jersey" },
  { label: "New Mexico", value: "new mexico" },
  { label: "New York", value: "new york" },
  { label: "North Carolina", value: "north carolina" },
  { label: "North Dakota", value: "north dakota" },
  { label: "Ohio", value: "ohio" },
  { label: "Oklahoma", value: "oklahoma" },
  { label: "Oregon", value: "oregon" },
  { label: "Pennsylvania", value: "pennsylvania" },
  { label: "Rhode Island", value: "rhode island" },
  { label: "South Carolina", value: "south carolina" },
  { label: "South Dakota", value: "south dakota" },
  { label: "Tennessee", value: "tennessee" },
  { label: "Texas", value: "texas" },
  { label: "Utah", value: "utah" },
  { label: "Vermont", value: "vermont" },
  { label: "Virginia", value: "virginia" },
  { label: "Washington", value: "washington" },
  { label: "West Virginia", value: "west virginia" },
  { label: "Wisconsin", value: "wisconsin" },
  { label: "Wyoming", value: "wyoming" },
];

export const plans = {
  basic: "BASIC",
  premium: "PREMIUM",
  allFeatures: "ALL_FEATURES",
};

export const plansPrice = {
  BASIC: 0,
  PREMIUM: 34.99,
  ALL_FEATURES: 250.0,
};
