import { Box, Button, Heading } from "@chakra-ui/react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useState, useEffect, useCallback } from "react";
import { useCreateCheckoutSessionMutation } from "../../services/stripeApi";
import { useNavigate } from "react-router-dom";
import { useFormik, useFormikContext } from "formik";

import { clearRegistrationData } from "../../store/registrationSlice";
import { AppDispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { sendGuardianPaidEmail } from "../../store/authSlice";

interface PaymentStepProps {
  handleNextStep: () => void;
  handlePreviousStep: () => void;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

const PaymentStep: React.FC<PaymentStepProps> = ({
  handleNextStep,
  handlePreviousStep,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [proceedNext, setProceedNext] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const registrationData = useSelector(
    (state: RootState) => state.registration
  );
  const [createCheckoutSession] = useCreateCheckoutSessionMutation();

  const fetchClientSecret = useCallback(async () => {
    const response = await createCheckoutSession({}).unwrap();
    setClientSecret(response.clientSecret);
    return response.clientSecret;
  }, [createCheckoutSession]);

  useEffect(() => {
    fetchClientSecret();
  }, [fetchClientSecret]);

  const options = {
    fetchClientSecret: async () => clientSecret || "",
    onComplete: async () => {
      await dispatch(
        sendGuardianPaidEmail({
          email: registrationData.userInformation.email,
          firstName: registrationData.userInformation.firstName,
        })
      );
      setProceedNext(true);
    },
  };

  const handleSubmitAndLogin = () => {
    // send the email the guardian paid
    navigate("/");
  };

  return (
    <Box>
      <Box>
        <Heading
          as="h1"
          textAlign="left"
          fontSize="45px"
          fontWeight="500"
          color="custom.blue"
          mb={8}
        >
          Payment
        </Heading>

        <Box>
          {clientSecret ? (
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          ) : (
            <Box>Loading...</Box> // Show loading until clientSecret is available
          )}
        </Box>

        <Box display="flex" justifyContent="end" gap={7} my={10}>
          <Box display="flex" justifyContent={{ base: "center", md: "end" }}>
            <Button
              _active={{ bg: "custom.white" }}
              _hover={{ bg: "gray.100" }}
              backgroundColor="custom.white"
              color="custom.grayIcon"
              border="1px solid"
              borderColor="custom.grayIcon"
              width={"150px"}
              px={8}
              onClick={handlePreviousStep}
            >
              Back
            </Button>
          </Box>
          <Box
            display="flex"
            justifyContent={{ base: "center", md: "end" }}
            hidden={!proceedNext}
          >
            <Button
              backgroundColor="custom.blue"
              color="custom.white"
              width={"150px"}
              _hover={{ bg: "custom.blue" }}
              onClick={() => {
                dispatch(clearRegistrationData());
                navigate("/");
              }}
              px={8}
            >
              Submit & Login
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentStep;
