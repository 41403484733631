import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  PinInput,
  PinInputField,
  Text,
  VStack,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addRegistrationDetails } from "../../store/registrationSlice";
import { useState } from "react";
import { RootState } from "../../store";

interface EmailVerificationStepProps {
  handleNextStep: () => void;
  handlePreviousStep: () => void;
}

const validationSchema = Yup.object().shape({
  otp: Yup.string()
    .trim()
    .min(6, "Please provide verification code")
    .required("Please provide verification code"),
});

const otpFieldStyle = {
  background: "custom.bg",
  '&[aria-invalid="true"]': {
    border: "1px solid",
    borderColor: "custom.red !important",
    boxShadow: "none",
  },
  "&:focus": {
    border: "1px solid",
    borderColor: "custom.blue",
    boxShadow: "none",
  },
  height: { base: "3rem", sm: "4rem", md: "5rem" },
  width: { base: "3rem", sm: "4rem", md: "5rem" },
  fontSize: { base: "25px", sm: "30px", md: "45px" },
  border: "none",
};

const EmailVerificationStep: React.FC<EmailVerificationStepProps> = ({
  handleNextStep,
  handlePreviousStep,
}) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const dispatch = useDispatch();
  const [placeholder, setPlaceholder] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const [message, setMessage] = useState("");
  const userInformation = useSelector(
    (state: RootState) => state.registration.userInformation
  );

  const formik = useFormik({
    initialValues: { otp: "" },
    validationSchema,
    onSubmit: async (values) => {
      try {
        // For testing purposes
        if (values.otp === "123456") {
          handleNextStep();
        }
        // Retrieve the email from Redux state
        let email = userInformation.email;
        if (!email) {
          email = localStorage.getItem("statsEmail") || "";
        }

        // Prepare the payload for the API request
        const payload = {
          email: email,
          code: values.otp, // The OTP entered by the user
        };
        setIsLoading(true);
        // Make the API request to verify the code
        const response = await fetch(`${BASE_URL}/api/auth/verify`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          setIsLoading(false);
          throw new Error("Failed to verify the code");
        }

        const data = await response.json();
        console.log("Verification successful:", data);

        // If successful, update the registration state and proceed to the next step
        dispatch(
          addRegistrationDetails({
            key: "isEmailVerified",
            data: true,
          })
        );
        setIsLoading(false);
        // Proceed to the next step
        handleNextStep();
      } catch (error) {
        console.error("Verification failed:", error);
      }
    },
  });

  const resendCode = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/auth/resend-code`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: userInformation.email,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 500) {
          setMessage(
            `Internal server error. Please try again later. Error: ${errorData.message}`
          );
          setIsMessageVisible(true);
          setTimeout(() => {
            setIsMessageVisible(false);
          }, 5000);
        }
        return false;
      }

      const data = await response.json();
      setIsMessageVisible(true);
      setMessage(data.message);
      setTimeout(() => {
        setIsMessageVisible(false);
      }, 5000);
      return true;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Box position="relative">
      <Box
        minHeight={{ base: "45vh", lg: "75vh" }}
        display="flex"
        alignItems={{ base: "start", md: "center" }}
      >
        <VStack
          align={{ base: "center", md: "start" }}
          w={{ base: "100%", md: "60%" }}
        >
          <Heading
            as="h1"
            textAlign="left"
            fontSize={{ base: "30px", md: "45px" }}
            fontWeight="500"
            color="custom.blue"
            mb={8}
          >
            Email Validation
          </Heading>
          <Text
            color="custom.gray2"
            fontSize="18px"
            textAlign={{ base: "center", md: "left" }}
          >
            {" "}
            An email containing a verification code has been sent to your email
            address
          </Text>
          <HStack
            pt="15px"
            pb="15px"
            display="flex"
            justifyContent={{ base: "center", md: "start" }}
          >
            <FormControl
              isInvalid={!!formik.touched.otp && !!formik.errors.otp}
            >
              <HStack>
                <PinInput
                  size="lg"
                  type="alphanumeric"
                  placeholder={placeholder}
                  otp
                  onChange={(value) => {
                    setPlaceholder("-");
                    formik.setFieldValue("otp", value);
                  }}
                  value={formik.values.otp}
                  isInvalid={!!formik.touched.otp && !!formik.errors.otp}
                >
                  <PinInputField sx={{ ...otpFieldStyle }} />
                  <PinInputField sx={{ ...otpFieldStyle }} />
                  <PinInputField sx={{ ...otpFieldStyle }} />
                  <PinInputField sx={{ ...otpFieldStyle }} />
                  <PinInputField sx={{ ...otpFieldStyle }} />
                  <PinInputField sx={{ ...otpFieldStyle }} />
                </PinInput>
              </HStack>
              <FormErrorMessage>{formik.errors.otp}</FormErrorMessage>
            </FormControl>
          </HStack>
          <HStack width="100%" justify={{ base: "end", md: "start" }}>
            <Text
              color="custom.blue1"
              fontWeight="bold"
              cursor="pointer"
              onClick={resendCode}
            >
              Resend
            </Text>
            <Text hidden={!isMessageVisible} fontWeight="bold" cursor="pointer">
              {`Verification code has been resent!`}
            </Text>
          </HStack>
        </VStack>
      </Box>

      <Box
        display="flex"
        justifyContent={{ base: "space-between", md: "end" }}
        gap={7}
        mt={5}
      >
        <Box display="flex" justifyContent={{ base: "center", md: "end" }}>
          <Button
            _active={{ bg: "custom.white" }}
            _hover={{ bg: "gray.100" }}
            backgroundColor="custom.white"
            color="custom.grayIcon"
            border="1px solid"
            borderColor="custom.grayIcon"
            width={{ base: "130px", md: "150px" }}
            px={8}
            onClick={handlePreviousStep}
          >
            Back
          </Button>
        </Box>
        <Box display="flex" justifyContent={{ base: "center", md: "end" }}>
          <Button
            isLoading={isLoading}
            backgroundColor="custom.blue"
            color="custom.white"
            width={{ base: "130px", md: "150px" }}
            _hover={{ bg: "custom.blue" }}
            onClick={formik.submitForm}
            px={8}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default EmailVerificationStep;
