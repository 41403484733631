import { Box, Button, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import { LuCrown } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  addRegistrationDetails,
  sendManagerEmail,
} from "../../store/registrationSlice";
import SubscriptionTierCard from "./SubscriptionTierCard";
import { planType } from "../../types/pages/registration";
import {
  BlueTick,
  PremiumTierCardIcon,
  StarterTierCardIcon,
} from "../../assets/SVGs/Global";
import { plans, plansPrice } from "../../utils/constants";

interface RegistrationStepProps {
  handleNextStep: () => void;
  handlePreviousStep: () => void;
  handleSubscriptionSelection: (type: string) => void;
}

const validationSchema = Yup.object().shape({
  selectedSubscription: Yup.string().trim().required("Please select a tier"),
});

const SubscriptionStep: React.FC<RegistrationStepProps> = ({
  handleNextStep,
  handlePreviousStep,
  handleSubscriptionSelection,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { userInformation, subscriptionType } = useSelector(
    (state: RootState) => state.registration
  );

  const formik = useFormik({
    initialValues: { selectedSubscription: null } as {
      selectedSubscription: planType | null;
    },
    validationSchema,
    onSubmit: async (values) => {
      dispatch(
        addRegistrationDetails({
          key: "subscriptionType",
          data: values.selectedSubscription,
        })
      );

      if (
        values.selectedSubscription === plans.basic ||
        userInformation.role === "Scout"
      ) {
        // Send the email to the manager here
        if (userInformation.role === "Scout") {
          console.log("Sending email to the manager");
          try {
            const result = await dispatch(
              sendManagerEmail({
                email: userInformation.email,
                name: `${userInformation.firstName} ${userInformation.lastName}`,
              })
            ).unwrap();
            console.log("Email sent successfully:", result);
          } catch (error) {
            console.error("Failed to send email:", error);
            // Handle the error (e.g., show an error message to the user)
          }
        }
        handleNextStep();
      } else {
        handleNextStep();
      }
    },
  });

  const handleChangeFieldValue = (type: string) => {
    formik.setFieldValue("selectedSubscription", type);
    handleSubscriptionSelection(type);
  };

  useEffect(() => {
    if (userInformation.role === "Scout") {
      formik.setFieldValue("selectedSubscription", plans.allFeatures);
    }
  }, [userInformation.role]);

  useEffect(() => {
    if (subscriptionType) {
      formik.setValues({ selectedSubscription: subscriptionType });
    }
  }, [subscriptionType]);

  return (
    <Box>
      <Heading
        as="h1"
        textAlign="left"
        fontSize={{ base: "30px", md: "45px" }}
        fontWeight="600"
        color="custom.blue"
        mb={8}
      >
        Subscription Plan
      </Heading>
      <Box>
        {userInformation.role === "Guardian" && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems={{ base: "center", md: "stretch" }}
            gap={7}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Box flexBasis="100%" flexGrow="1">
              <SubscriptionTierCard
                heading="Starter Tier"
                description="View general statistics of players in the league as well as your created players."
                isActive={formik.values.selectedSubscription === plans.basic}
                icon={<StarterTierCardIcon />}
                price={plansPrice.BASIC}
                planDetails={
                  <Box
                    color="custom.gray3"
                    fontSize="14px"
                    fontWeight="500"
                    display="flex"
                    flexDirection="column"
                    gap="8px"
                  >
                    <Box display="flex" gap={3}>
                      <Box>
                        <BlueTick />
                      </Box>
                      <Box>
                        <Text>General Stats:</Text>
                        <Text pl="22px">
                          <ul>
                            <li>RBI</li>
                            <li>OUT</li>
                            <li>HIT</li>
                            <li>ERA</li>
                            <li>OBP</li>
                            <li>WAR</li>
                            <li>HR</li>
                            <li>Errors</li>
                          </ul>
                        </Text>
                      </Box>
                    </Box>
                    <Box display="flex" gap={3}>
                      <Box>
                        <BlueTick />
                      </Box>
                      <Box>
                        <Text>Showcase Events*</Text>
                      </Box>
                    </Box>
                  </Box>
                }
                onClick={() => handleChangeFieldValue(plans.basic)}
              />
            </Box>
            <Box flexBasis="100%" flexGrow="1">
              <SubscriptionTierCard
                heading="Premium Tier"
                description="Our premium tier offers all of the standard tier plus more insight and analytics of your created players. only for guardian users"
                isActive={formik.values.selectedSubscription === plans.premium}
                icon={<PremiumTierCardIcon />}
                price={plansPrice.PREMIUM}
                planDetails={
                  <Box
                    color="custom.gray3"
                    fontSize="14px"
                    fontWeight="500"
                    display="flex"
                    flexDirection="column"
                    gap="8px"
                  >
                    <Box display="flex" gap={3}>
                      <Box>
                        <BlueTick />
                      </Box>
                      <Box>
                        {" "}
                        <Text>
                          General Stats (AVG, RBI, OUT, HIT, ERA, amongothers)
                        </Text>
                      </Box>
                    </Box>
                    <Box display="flex" gap={3}>
                      <Box>
                        <BlueTick />
                      </Box>
                      <Box>
                        {" "}
                        <Text>Showcase Events*</Text>
                      </Box>
                    </Box>
                    <Box display="flex" gap={3}>
                      <Box>
                        <BlueTick />
                      </Box>
                      <Box>
                        {" "}
                        <Text>Baseball Card Template</Text>
                      </Box>
                    </Box>
                    <Box display="flex" gap={3}>
                      <Box>
                        <BlueTick />
                      </Box>
                      <Box>
                        {" "}
                        <Text>Batting Hot Zones</Text>
                      </Box>
                    </Box>
                    <Box display="flex" gap={3}>
                      <Box>
                        <BlueTick />
                      </Box>
                      <Box>
                        {" "}
                        <Text>Player Stats by Timeframe</Text>
                      </Box>
                    </Box>
                    <Box display="flex" gap={3}>
                      <Box>
                        <BlueTick />
                      </Box>
                      <Box>
                        {" "}
                        <Text>
                          Ranking Scores in AVG, HIT, RBI, Singles, Double, HR
                          OBP and WAR of the league in the season or tournament.
                        </Text>
                      </Box>
                    </Box>
                    <Box display="flex" gap={3}>
                      <Box>
                        <BlueTick />
                      </Box>
                      <Box>
                        {" "}
                        <Text>Top 50 Player List</Text>
                      </Box>
                    </Box>
                    <Box display="flex" gap={3}>
                      <Box>
                        <BlueTick />
                      </Box>
                      <Box>
                        {" "}
                        <Text>
                          PVP comparison (Guardian Player with another in the
                          same league)
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                }
                onClick={() => handleChangeFieldValue("PREMIUM")}
              />
            </Box>
          </Box>
        )}
        {userInformation.role === "Scout" && (
          <Box
            display="flex"
            justifyContent={{ base: "center", md: "start" }}
            alignItems={{ base: "center", md: "stretch" }}
            gap={7}
            flexDirection={{ base: "column", md: "row" }}
          >
            <SubscriptionTierCard
              heading="All features"
              description="Access all features"
              isActive={
                formik.values.selectedSubscription === plans.allFeatures
              }
              icon={<LuCrown style={{ height: "25px", width: "25px" }} />}
              onClick={() => handleChangeFieldValue(plans.allFeatures)}
              price={plansPrice.ALL_FEATURES}
              hasButton={false}
              planDetails={
                <Box
                  color="custom.gray3"
                  fontSize="14px"
                  fontWeight="500"
                  display="flex"
                  flexDirection="column"
                  gap="8px"
                >
                  <Box display="flex" gap={3}>
                    <Box>
                      <BlueTick />
                    </Box>
                    <Box>
                      {" "}
                      <Text>
                        General Stats (AVG, RBI, OUT, HIT. ERA, among others)
                      </Text>
                    </Box>
                  </Box>
                  <Box display="flex" gap={3}>
                    <Box>
                      <BlueTick />
                    </Box>
                    <Box>
                      {" "}
                      <Text>Showcase Events Metrics of all players</Text>
                    </Box>
                  </Box>
                  <Box display="flex" gap={3}>
                    <Box>
                      <BlueTick />
                    </Box>
                    <Box>
                      {" "}
                      <Text>Baseball Card Template</Text>
                    </Box>
                  </Box>
                  <Box display="flex" gap={3}>
                    <Box>
                      <BlueTick />
                    </Box>
                    <Box>
                      {" "}
                      <Text>Batting Hot Zones</Text>
                    </Box>
                  </Box>
                  <Box display="flex" gap={3}>
                    <Box>
                      <BlueTick />
                    </Box>
                    <Box>
                      {" "}
                      <Text>Player Stats by TimeFrame</Text>
                    </Box>
                  </Box>
                  <Box display="flex" gap={3}>
                    <Box>
                      <BlueTick />
                    </Box>
                    <Box>
                      {" "}
                      <Text>
                        Ranking Score in AVG, HIT, RBI, Singles, Doubles, HR OBP
                        and WAR of the league in the season or tournament
                      </Text>
                    </Box>
                  </Box>
                  <Box display="flex" gap={3}>
                    <Box>
                      <BlueTick />
                    </Box>
                    <Box>
                      {" "}
                      <Text>
                        Top 50 player list in AVG, HIT, HR, OBP, WAR and Errors
                      </Text>
                    </Box>
                  </Box>
                  <Box display="flex" gap={3}>
                    <Box>
                      <BlueTick />
                    </Box>
                    <Box>
                      {" "}
                      <Text>PVP Comparison</Text>
                    </Box>
                  </Box>
                </Box>
              }
            />
          </Box>
        )}
        {formik.errors.selectedSubscription && (
          <Box mt={5} display="flex">
            <Text color="custom.red">{formik.errors.selectedSubscription}</Text>
          </Box>
        )}
      </Box>
      <Box
        display="flex"
        justifyContent={{ base: "space-between", md: "end" }}
        gap={7}
        my={10}
      >
        <Box display="flex" justifyContent={{ base: "center", md: "end" }}>
          <Button
            _active={{ bg: "custom.white" }}
            _hover={{ bg: "gray.100" }}
            backgroundColor="custom.white"
            color="custom.grayIcon"
            border="1px solid"
            borderColor="custom.grayIcon"
            width={{ base: "130px", md: "150px" }}
            px={8}
            onClick={handlePreviousStep}
          >
            Back
          </Button>
        </Box>
        <Box display="flex" justifyContent={{ base: "center", md: "end" }}>
          <Button
            backgroundColor="custom.blue"
            color="custom.white"
            width={{ base: "130px", md: "150px" }}
            _hover={{ bg: "custom.blue" }}
            onClick={formik.submitForm}
            px={8}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SubscriptionStep;
