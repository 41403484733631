import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AppDispatch } from "../../store";
import { confirmForgotPassword } from "../../store/registrationSlice"; // Assuming this is where the thunk is located
import PasswordInput from "../common/PasswordInput";

const SetANewPassword: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  // State to track submission success
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);

  // Validation schema using Yup
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    code: Yup.string().required("PIN code is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), undefined], "Passwords must match")
      .required("Confirm password is required"),
  });

  // Formik hook to manage form state and validation
  const formik = useFormik({
    initialValues: {
      email: "",
      code: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        // Dispatch the confirmForgotPassword thunk with email, code, and new password
        const resultAction = await dispatch(
          confirmForgotPassword({
            email: values.email,
            code: values.code,
            newPassword: values.password,
          })
        );

        if (confirmForgotPassword.fulfilled.match(resultAction)) {
          console.log("Password reset successful");
          setPasswordResetSuccess(true); // Mark reset as successful
        } else if (confirmForgotPassword.rejected.match(resultAction)) {
          setErrors({ email: resultAction.payload as string });
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  // Handle navigation after successful reset
  const handleContinue = () => {
    navigate("/");
  };

  console.log(formik.values.password);

  return (
    <Box>
      <Box
        mt={8}
        minHeight={{ base: "45vh", lg: "75vh" }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Heading
          as="h1"
          textAlign="left"
          fontSize={{ base: "30px", md: "45px" }}
          fontWeight="500"
          color="custom.blue"
          mb={10}
        >
          Set A New Password
        </Heading>
        {!passwordResetSuccess ? (
          <>
            <Text fontSize={{ base: "20px", md: "24px" }} mb="10px">
              <Text as="span" color="custom.grayIcon">
                Enter the PIN code from your email and set a new password.
              </Text>
            </Text>

            <form onSubmit={formik.handleSubmit}>
              <FormControl
                isInvalid={!!formik.errors.email && formik.touched.email}
                isRequired
                mb={5}
              >
                <FormLabel color="gray">Email</FormLabel>
                <Input
                  placeholder="john.doe@gmail.com"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email ? (
                  <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                ) : null}
              </FormControl>

              <FormControl
                isInvalid={!!formik.errors.code && formik.touched.code}
                isRequired
                mb={5}
              >
                <FormLabel color="gray">PIN Code</FormLabel>
                <Input
                  placeholder="123456"
                  name="code"
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.code && formik.errors.code ? (
                  <FormErrorMessage>{formik.errors.code}</FormErrorMessage>
                ) : null}
              </FormControl>

              <FormControl
                isInvalid={!!formik.errors.password && formik.touched.password}
                isRequired
                mb={5}
                id="password"
              >
                <FormLabel color="gray">New Password</FormLabel>
                <PasswordInput
                  placeholder="****"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.password && formik.errors.password ? (
                  <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                ) : null}
              </FormControl>

              <FormControl
                isInvalid={
                  !!formik.errors.confirmPassword &&
                  formik.touched.confirmPassword
                }
                isRequired
                mb={5}
              >
                <FormLabel color="gray">Confirm Password</FormLabel>
                <PasswordInput
                  placeholder="****"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <FormErrorMessage>
                    {formik.errors.confirmPassword}
                  </FormErrorMessage>
                ) : null}
              </FormControl>

              <Box
                display="flex"
                justifyContent={{ base: "space-between", md: "end" }}
                gap={7}
                my={10}
              >
                <Button
                  backgroundColor="custom.blue"
                  color="custom.white"
                  width={{ base: "130px", md: "150px" }}
                  _hover={{ bg: "custom.blue" }}
                  isLoading={formik.isSubmitting}
                  type="submit"
                  px={8}
                >
                  Reset Password
                </Button>
              </Box>
            </form>
          </>
        ) : (
          <Box mt={10}>
            <Text
              textAlign="left"
              fontSize={{ base: "20px", md: "24px" }}
              mb={10}
            >
              Password reset successful! You can now log in with your new
              password.
            </Text>

            <Box display="flex" justifyContent="start" gap={5} float={"right"}>
              <Button
                backgroundColor="custom.blue"
                color="custom.white"
                width={{ base: "130px", md: "150px" }}
                _hover={{ bg: "custom.blue" }}
                onClick={handleContinue}
              >
                Login
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SetANewPassword;
