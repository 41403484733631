export const formatDate = (date: Date): string => {
  return new Intl.DateTimeFormat("en-US").format(date);
};

export const mapUserAttributes = (
  attributes: { Name: string; Value: string }[]
) => {
  return attributes.reduce((acc: any, attribute) => {
    acc[attribute.Name] = attribute.Value;
    return acc;
  }, {});
};
