import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { UserIconFill } from "../../assets/SVGs/Global";
import { FC, SetStateAction } from "react";

interface DeniedUserPopupProps {
  isLoading: boolean;
  isOpenRequestDetailPopup: boolean;
  toggleRequestDetailPopup: () => void;
  selectedRequest: any;
  handleAddResolutionNote: () => void;
  resolutionNote: string;
  setResolutionNote: React.Dispatch<React.SetStateAction<string>>;
}

const DeniedUserPopup: FC<DeniedUserPopupProps> = ({
  toggleRequestDetailPopup,
  isOpenRequestDetailPopup,
  selectedRequest,
  isLoading,
  handleAddResolutionNote,
  resolutionNote,
  setResolutionNote,
}) => {
  return (
    <Modal
      onClose={toggleRequestDetailPopup}
      isOpen={isOpenRequestDetailPopup}
      size={{ base: "xs", sm: "sm", md: "2xl", lg: "3xl" }}
    >
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(3px)" />
      <ModalContent my={"auto"} pt={"30px"} pb={"14px"} borderRadius={"xl"}>
        <Flex justifyContent={"space-between"} px={"30px"} pb={"30px"}>
          <Stack display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <UserIconFill />
            <Text color={"#232327B2"} fontSize={18}>
              {selectedRequest?.name}
            </Text>
          </Stack>
          <Stack
            display={"inline-flex"}
            flexDirection={"row"}
            alignItems={"center"}
          >
            <Text color={"#232327B2"} fontSize={18}>
              {selectedRequest?.createdDate}
            </Text>
          </Stack>
        </Flex>
        <hr />
        <ModalBody
          display={"flex"}
          flexDirection={"column"}
          fontSize={"18px"}
          gap={{ base: 3, md: 6 }}
          mt={{ base: "10px", md: "30px" }}
          px={"30px"}
        >
          <Flex
            gap={2}
            justifyContent={"space-between"}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Flex
              flexWrap={"nowrap"}
              flexDirection={"column"}
              alignItems={{ base: "start" }}
              w="100%"
            >
              <Text
                color={"#959595"}
                textAlign={"start"}
                fontSize={"16px"}
                fontWeight={"semibold"}
              >
                Email
              </Text>
              <Text color={"#232327"} as={"b"}>
                {selectedRequest?.Email}
              </Text>
            </Flex>
            <Flex
              flexWrap={"nowrap"}
              flexDirection={"column"}
              alignItems={{ base: "start" }}
              w="100%"
            >
              <Text color={"#959595"} fontSize={"16px"} fontWeight={"semibold"}>
                Gender
              </Text>
              <Text color={"#232327"} as={"b"}>
                {selectedRequest?.Gender}
              </Text>
            </Flex>
            <Flex
              flexWrap={"nowrap"}
              flexDirection={"column"}
              alignItems={{ base: "start" }}
              w="100%"
            >
              <Text color={"#959595"} fontSize={"16px"} fontWeight={"semibold"}>
                Telephone
              </Text>
              <Text color={"#232327"} as={"b"} textAlign="start">
                {selectedRequest?.telephoneNumber}
              </Text>
            </Flex>
            <Flex
              flexWrap={"nowrap"}
              flexDirection={"column"}
              alignItems={{ base: "start" }}
              w="100%"
            >
              <Text color={"#959595"} fontSize={"16px"} fontWeight={"semibold"}>
                Birthdate
              </Text>
              <Text color={"#232327"} as={"b"}>
                {selectedRequest?.BirthDate}
              </Text>
            </Flex>
          </Flex>
          <Flex
            flexWrap={"nowrap"}
            flexDirection={"column"}
            w="100%"
            alignItems={"start"}
          >
            <Text color={"#959595"} fontSize={"16px"} fontWeight={"semibold"}>
              Address
            </Text>
            <Text color={"#232327"} as={"b"} textAlign="start">
              {selectedRequest?.Address}
            </Text>
          </Flex>
          <Flex flexDirection={"column"} w="100%" alignItems={"start"}>
            <Text color={"#959595"} fontSize={"16px"} fontWeight={"semibold"}>
              Resolution Note
            </Text>
            <Textarea
              border={"none"}
              borderRadius={"md"}
              bg={"#F2F2F2"}
              value={resolutionNote}
              onChange={(e) => {
                setResolutionNote(e.target.value);
                console.log("Resolution note updated:", e.target.value);
              }}
              // marginBottom={7}
              size="sm"
              placeholder="Here is a sample placeholder"
            />
          </Flex>
        </ModalBody>
        <ModalFooter
          display={"flex"}
          justifyContent={"end"}
          gap={"26px"}
          px={"30px"}
        >
          <Button
            variant="outline"
            color={"#545560"}
            borderColor={"#545560"}
            h={12}
            w="180px"
            onClick={toggleRequestDetailPopup}
          >
            {" "}
            Cancel{" "}
          </Button>
          <Button
            bgColor={"#1B154B"}
            variant={"solid"}
            colorScheme="darkpurple"
            color={"white"}
            h={12}
            _hover={{ bg: "#1B154B", opacity: 0.8 }}
            w="180px"
            isLoading={isLoading}
            onClick={handleAddResolutionNote}
          >
            {" "}
            Submit{" "}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeniedUserPopup;
