import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { VerificationPayload } from "../types/pages/registration";

interface userInformation {
  firstName: string;
  initial?: string;
  lastName: string;
  phone: string;
  email: string;
  confirmEmail?: string;
  password: string;
  confirmPassword?: string;
  role: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state?: string;
  zipCode: string;
  dob: Date | null;
  gender?: string;
}

type SubscriptionType = "BASIC" | "PREMIUM" | "ALL_FEATURES" | null;

interface RegistrationDetailsState {
  userInformation: userInformation;
  isEmailVerified: boolean;
  subscriptionType: SubscriptionType;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: RegistrationDetailsState = {
  userInformation: {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
    role: "",
    addressLine1: "",
    city: "",
    zipCode: "",
    dob: null,
  },
  isEmailVerified: false,
  subscriptionType: null,
  status: "idle",
  error: null,
};

export const sendVerificationCode = createAsyncThunk(
  "registration/sendVerificationCode",
  async (payload: VerificationPayload, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/auth/signup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        // This will handle 500 and other error responses
        return rejectWithValue(
          data.message || "An error occurred. Please try again."
        );
      }

      return data;
    } catch (error) {
      return rejectWithValue("Network error. Please try again.");
    }
  }
);

export const sendManagerEmail = createAsyncThunk(
  "registration/sendManagerEmail",
  async (payload: { email: string; name: string }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/auth/send-email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(
          errorData.message || "Failed to send manager email"
        );
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue("Network error. Failed to send manager email.");
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "registration/forgotPassword",
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/auth/forgot-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        // Handle server error responses
        return rejectWithValue(
          data.message || "Failed to send password reset email."
        );
      }

      return data;
    } catch (error) {
      // Handle network errors
      return rejectWithValue("Network error. Please try again.");
    }
  }
);

export const confirmForgotPassword = createAsyncThunk(
  "registration/confirmForgotPassword",
  async (
    payload: { email: string; code: string; newPassword: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/auth/confirm-forgot-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data.message || "Failed to reset password.");
      }

      return data;
    } catch (error) {
      return rejectWithValue("Network error. Please try again.");
    }
  }
);

const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    addRegistrationDetails: (
      state,
      action: PayloadAction<{
        key: string;
        data: userInformation | boolean | SubscriptionType;
      }>
    ) => {
      const { key, data } = action.payload;
      return {
        ...state,
        [key]: data,
      };
    },
    clearRegistrationData: () => {
      return { ...initialState };
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })

      // Confirm Forgot Password Cases
      .addCase(confirmForgotPassword.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(confirmForgotPassword.fulfilled, (state) => {
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(confirmForgotPassword.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(sendVerificationCode.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(sendVerificationCode.fulfilled, (state) => {
        state.status = "succeeded";
        state.isEmailVerified = true;
        state.error = null;
      })
      .addCase(sendVerificationCode.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(sendManagerEmail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendManagerEmail.fulfilled, (state) => {
        state.status = "succeeded";
        // You might want to set a flag or message indicating the email was sent
        // state.managerEmailSent = true;
      })
      .addCase(sendManagerEmail.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

export const { addRegistrationDetails, clearRegistrationData, clearError } =
  registrationSlice.actions;
export default registrationSlice.reducer;
