import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Stat,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  ClockIcon,
  FindUser,
  GreenCheck,
  GreenCircleCheck,
  MoreIcon,
  RedCircleCross,
  RedCross,
  SearchIcon,
  UserIconFill,
} from "../assets/SVGs/Global";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import AlertPopup from "../components/common/AlertPopup";
import {
  addDenyResolutionNote,
  approveRequestData,
  denyRequestData,
  getPendingRequestUserData,
  getRequestHistoryUserData,
} from "../store/slices/requestSlice";
import DeniedUserPopup from "../components/account/DeniedUserPopup";
import { acceptScout, denyScout, getScouts } from "../store/authSlice";

const data = [
  {
    id: 1,
    createdDate: "08/08/2024",
    createdTime: "12:10 AM",
    name: "Luis h Joik",
    telephoneNumber: "(787) 000 0000",
    Email: "Luishjoik@gmail.com",
    Gender: "male",
    BirthDate: "02/01/1987",
    Address: "22, packet Square, suite, san juan, pR, USA -00901",
    status: "Denied",
  },

  {
    id: 2,
    createdDate: "09/15/2024",
    createdTime: "02:25 PM",
    name: "Maria L. Ruiz",
    telephoneNumber: "(787) 111 1111",
    Email: "mariaruiz@email.com",
    Gender: "female",
    BirthDate: "05/22/1990",
    Address: "11, maple Avenue, apt 5B, bayamon, pR, USA -00957",
    status: "Approved",
  },

  {
    id: 3,
    createdDate: "09/17/2024",
    createdTime: "04:30 AM",
    name: "Carlos A. Rivera",
    telephoneNumber: "(787) 222 2222",
    Email: "carlosrivera@email.com",
    Gender: "male",
    BirthDate: "10/14/1983",
    Address: "45, main street, condado, san juan, pR, USA -00907",
    status: "Denied",
  },

  {
    id: 4,
    createdDate: "10/05/2024",
    createdTime: "08:00 AM",
    name: "Ana G. Morales",
    telephoneNumber: "(787) 333 3333",
    Email: "anamoraless@gmail.com",
    Gender: "female",
    BirthDate: "11/30/1995",
    Address: "32, garden plaza, suite 7, ponce, pR, USA -00716",
    status: "Approved",
  },

  {
    id: 5,
    createdDate: "09/28/2024",
    createdTime: "06:50 PM",
    name: "José M. González",
    telephoneNumber: "(787) 444 4444",
    Email: "josemgonzalez@email.com",
    Gender: "male",
    BirthDate: "01/05/1982",
    Address: "54, oak ridge, apt 3C, mayaguez, pR, USA -00680",
    status: "Denied",
  },

  {
    id: 6,
    createdDate: "09/29/2024",
    createdTime: "10:15 AM",
    name: "Sofia P. Martinez",
    telephoneNumber: "(787) 555 5555",
    Email: "sofia.martinez@email.com",
    Gender: "female",
    BirthDate: "03/14/1992",
    Address: "77, sunrise lane, suite 4, guaynabo, pR, USA -00969",
    status: "Approved",
  },

  {
    id: 7,
    createdDate: "10/01/2024",
    createdTime: "09:30 PM",
    name: "Roberto L. Ortiz",
    telephoneNumber: "(787) 666 6666",
    Email: "roberto.ortiz@email.com",
    Gender: "male",
    BirthDate: "06/20/1986",
    Address: "89, cedar road, 2nd floor, rio piedras, pR, USA -00926",
    status: "Approved",
  },

  {
    id: 8,
    createdDate: "10/04/2024",
    createdTime: "03:40 AM",
    name: "Isabel R. Díaz",
    telephoneNumber: "(787) 777 7777",
    Email: "isabel.diaz@email.com",
    Gender: "female",
    BirthDate: "07/11/1989",
    Address: "18, blue water street, san juan, pR, USA -00914",
    status: "",
  },

  {
    id: 9,
    createdDate: "10/07/2024",
    createdTime: "01:55 PM",
    name: "David A. López",
    telephoneNumber: "(787) 888 8888",
    Email: "davidlopez@email.com",
    Gender: "male",
    BirthDate: "09/03/1994",
    Address: "66, port view avenue, suite 1A, fajardo, pR, USA -00738",
    status: "",
  },

  {
    id: 10,
    createdDate: "10/09/2024",
    createdTime: "05:00 AM",
    name: "Elena T. Santiago",
    telephoneNumber: "(787) 999 9999",
    Email: "elenasantiago@email.com",
    Gender: "female",
    BirthDate: "04/02/1985",
    Address: "100, coconut grove, apt 9D, dorado, pR, USA -00646",
    status: "",
  },
];
interface DataItem {
  id: number;
  createdDate: string;
  createdTime: string;
  name: string;
  telephoneNumber: string;
  Email: string;
  Gender: string;
  BirthDate: string;
  Address: string;
  status: string;
}
const ScoutAccess: FC = () => {
  const { scouts, loading } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();
  const { pendingRequestData, requestHistoryData, isLoading } = useSelector(
    (state: RootState) => state.request
  );

  const [isRequestView, setIsRequestView] = useState(true);
  const [filteredData, setFilteredData] = useState<DataItem[]>(
    isRequestView ? pendingRequestData : requestHistoryData
  );
  const [isOpenApprovePopup, setIsOpenApprovePopup] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<DataItem>(
    {} as DataItem
  );
  const [isOpenRequestDetailPopup, setIsOpenRequestDetailPopup] =
    useState(false);
  const [isOpenDenyPopup, setIsOpenDenyPopup] = useState(false);
  const [resolutionNote, setResolutionNote] = useState("");

  const toggleApprovePopup = () => setIsOpenApprovePopup(!isOpenApprovePopup);
  const toggleRequestDetailPopup = () => {
    setResolutionNote("");
    setIsOpenRequestDetailPopup(!isOpenRequestDetailPopup);
  };
  const toggleDenyPopup = () => setIsOpenDenyPopup(!isOpenDenyPopup);

  useEffect(() => {
    dispatch(getScouts());
  }, [dispatch]);

  useEffect(() => {
    if (scouts && scouts.length > 0) {
      // Transform the retrieved scouts data into the structure expected by your table.
      const transformedData = scouts
        .filter((scout) => {
          // If `isRequestView` is true, only include scouts with `VERDICT` of `null` (i.e., pending).
          // If `isRequestView` is false, include all scouts.
          if (isRequestView) {
            return scout.VERDICT === null;
          }
          return scout.VERDICT !== null;
        })
        .map((scout) => ({
          id: scout.SCOUT_REQUEST_ID,
          createdDate: new Date(scout.CREATED_DATE).toLocaleDateString(),
          createdTime: new Date(scout.CREATED_DATE).toLocaleTimeString(),
          name: scout.NAME,
          telephoneNumber: scout.PHONE_NUMBER,
          Email: scout.EMAIL,
          Gender: scout.GENDER,
          BirthDate: new Date(scout.BIRTHDATE).toLocaleDateString(),
          Address: scout.ADDRESS,
          status:
            scout.VERDICT === null
              ? "Pending"
              : scout.VERDICT
              ? "Approved"
              : "Denied",
        }));
      console.log("transformedData", transformedData.length);
      setFilteredData(transformedData);
    }
  }, [scouts, isRequestView]);

  const handleFilterData = (searchData: string) => {
    if (searchData) {
      const filterData = (
        isRequestView ? pendingRequestData : requestHistoryData
      )?.filter((item: any) => {
        if (item.name.includes(searchData.toLocaleLowerCase())) return item;
        else if (
          `${item.createdDate.toLocaleLowerCase()} ${item.createdTime.toLocaleLowerCase()}`.includes(
            searchData.toLocaleLowerCase()
          )
        )
          return item;
        else if (item.telephoneNumber.includes(searchData.toLocaleLowerCase()))
          return item;
        else if (
          item.Email.includes(searchData.toLocaleLowerCase()) ||
          item.Gender.includes(searchData.toLocaleLowerCase()) ||
          item.BirthDate.includes(searchData.toLocaleLowerCase()) ||
          item.Address.includes(searchData.toLocaleLowerCase())
        )
          return item;
        else if (
          !isRequestView &&
          item.status.includes(searchData.toLocaleLowerCase())
        )
          return item;
        else return;
      });
      setFilteredData(filterData);
    } else
      setFilteredData(isRequestView ? pendingRequestData : requestHistoryData);
  };

  const handleApproveRequest = (data: DataItem) => {
    dispatch(acceptScout({ email: data.Email, approverManager: 1 })).then(
      () => {
        toggleApprovePopup();
        dispatch(getScouts()); // Refetch the list of scouts after approval
      }
    );
  };

  const handleAddResolutionNote = () => {
    console.log("Resolution note on submit:", resolutionNote);
    if (!resolutionNote.trim()) {
      // Optionally handle empty resolution note error
      return;
    }

    handleDenyRequest(selectedRequest);
  };

  const handleDenyRequest = (data: DataItem) => {
    dispatch(
      denyScout({
        email: data.Email,
        managerNotes: resolutionNote,
        approverManager: 1,
      })
    ).then(() => {
      toggleDenyPopup();
      dispatch(getScouts()); // Refresh the list of scouts to reflect the updated status
    });
  };

  // useEffect(() => {
  //   if (isRequestView)
  //     setFilteredData(
  //       pendingRequestData?.filter((item: DataItem) => !item.status)
  //     );
  //   else
  //     setFilteredData(
  //       requestHistoryData?.filter((item: DataItem) => item.status)
  //     );
  // }, [isRequestView, pendingRequestData, requestHistoryData]);

  // useEffect(() => {
  //   if (pendingRequestData.length <= 0)
  //     dispatch(
  //       getPendingRequestUserData(
  //         data?.filter((item: DataItem) => !item.status)
  //       )
  //     );
  //   if (requestHistoryData.length <= 0)
  //     // history data get
  //     dispatch(
  //       getRequestHistoryUserData(data?.filter((item: DataItem) => item.status))
  //     );
  // }, [isRequestView]);

  return (
    <Box>
      <Heading
        mb={{ base: 3, md: 7 }}
        as="h3"
        fontSize={{ base: 18, md: 24, lg: 30, xl: 38 }}
      >
        {isRequestView ? "Pending Request" : "Request History"}
      </Heading>
      <Flex direction={"column"} gap="26px" justifyContent={"center"}>
        <Flex
          gap={2}
          justify="space-between"
          align={{ base: "end", md: "center" }}
          flexDirection={{ base: "column", sm: "row" }}
        >
          <InputGroup maxW={"300px"} minW={{ base: "100%", sm: "150px" }}>
            <InputLeftElement pointerEvents="none">
              <SearchIcon />
            </InputLeftElement>
            <Input
              type="tel"
              placeholder="Search"
              bg={"white"}
              color={"#545560"}
              onChange={(e) => {
                handleFilterData(e.target.value.trimEnd());
              }}
            />
          </InputGroup>
          <Flex
            gap={{ base: 1, md: 5 }}
            justify={{ base: "space-between", md: "normal" }}
            width={{ base: "100%", sm: "auto" }}
          >
            <Button
              leftIcon={isRequestView ? <ClockIcon /> : <FindUser />}
              backgroundColor={"#1B154B"}
              color={"white"}
              _hover={{ bg: "#1B154B", opacity: 0.8 }}
              onClick={() => {
                setIsRequestView(!isRequestView);
              }}
            >
              {isRequestView ? "Request History" : "Pending History"}
            </Button>
            <Button
              _hover={{ opacity: 0.8 }}
              m={0}
              p={0}
              justifyContent={"center"}
              variant="ghost"
            >
              <MoreIcon />
            </Button>
          </Flex>
        </Flex>
        <TableContainer>
          <Table
            variant="striped"
            colorScheme={"#D0D0E1"}
            sx={{
              "& tr:nth-of-type(even)": {
                backgroundColor: "#FFFFFF",
              },
              "& tr:nth-of-type(odd)": {
                backgroundColor: "#f2f2f2",
              },
              "& tr:last-child": {
                borderBottomLeftRadius: "lg",
                borderBottomRightRadius: "lg",
                overflow: "hidden",
              },
              "& tr:last-child td:first-child": {
                borderBottomLeftRadius: "lg",
              },
              "& tr:last-child td:last-child": {
                borderBottomRightRadius: "lg",
              },
              "& tr:first-child td:first-child": {
                borderTopLeftRadius: "lg",
              },
              "& tr:first-child td:last-child": {
                borderTopRightRadius: "lg",
              },
              "& th": {
                backgroundColor: "#f2f2f2",
                textTransform: "capitalize",
                fontSize: "16px",
                color: "#757575",
                paddingInline: 3,
                border: "none",
              },
              "& td": {
                textTransform: "capitalize",
                paddingInline: 3,
                border: "none",
              },
              "& td:not(last-child)": {
                fontWeight: "semibold",
                color: "#232327",
              },
            }}
          >
            <Thead>
              <Tr>
                <Th>Created date</Th>
                <Th>Name</Th>
                <Th>Telephone number</Th>
                <Th>Email</Th>
                <Th>Gender</Th>
                <Th>Birth date</Th>
                <Th>Address</Th>
                <Th>Verdict</Th>
              </Tr>
            </Thead>
            <Tbody
              sx={{
                border: "3px solid transparent",
                boxShadow: "0 0 0 1px #D0D0E1",
              }}
              borderRadius="lg"
            >
              {filteredData?.map((item: DataItem) => {
                return (
                  <>
                    <Tr
                      borderRadius="lg"
                      sx={{
                        "& td:last-child": {
                          color: `${
                            item.status == "Approved" ? "green" : "red"
                          }`,
                        },
                      }}
                    >
                      <Td>
                        <div>
                          <div>{item.createdDate}</div>
                          <div>{item.createdTime}</div>
                        </div>
                      </Td>
                      <Td>{item.name}</Td>
                      <Td>{item.telephoneNumber}</Td>
                      <Td>{item.Email}</Td>
                      <Td>{item.Gender}</Td>
                      <Td>{item.BirthDate}</Td>
                      <Td minW="200px" whiteSpace="normal">
                        {item.Address}
                      </Td>
                      {isRequestView ? (
                        <Td>
                          <Flex gap={1}>
                            <Button
                              p={0}
                              m={0}
                              justifyContent={"center"}
                              variant="ghost"
                              onClick={() => {
                                toggleApprovePopup();
                                setSelectedRequest(item);
                              }}
                            >
                              {" "}
                              <GreenCircleCheck />{" "}
                            </Button>
                            <Button
                              p={0}
                              m={0}
                              justifyContent={"center"}
                              variant="ghost"
                              onClick={() => {
                                toggleRequestDetailPopup();
                                setSelectedRequest(item);
                              }}
                            >
                              {" "}
                              <RedCircleCross />{" "}
                            </Button>
                          </Flex>
                        </Td>
                      ) : (
                        <Td
                          textColor={
                            item.status == "Approved" ? "green" : "red"
                          }
                        >
                          {item.status}
                        </Td>
                      )}
                    </Tr>
                  </>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
      {isOpenApprovePopup && (
        <AlertPopup
          onClose={toggleApprovePopup}
          isOpen={isOpenApprovePopup}
          titleText={"Approved Successfully"}
          alertIcon={<GreenCheck />}
          footerElements={
            <>
              <Button
                bgColor={"#1B154B"}
                variant={"solid"}
                colorScheme="darkpurple"
                color={"white"}
                h={12}
                w="210px"
                isLoading={isLoading}
                onClick={() => {
                  handleApproveRequest(selectedRequest);
                }}
              >
                Ok
              </Button>
            </>
          }
          description="Payment will be charged and uses will be informed"
        />
      )}
      {isOpenDenyPopup && (
        <AlertPopup
          onClose={toggleDenyPopup}
          isOpen={isOpenDenyPopup}
          titleText={"Denied Request"}
          alertIcon={<RedCross />}
          footerElements={
            <>
              <Button
                bgColor={"#1B154B"}
                variant={"solid"}
                colorScheme="darkpurple"
                color={"white"}
                h={12}
                w="210px"
                isLoading={isLoading}
                onClick={() => {
                  // close the popup
                  toggleDenyPopup();
                  toggleRequestDetailPopup();
                }}
              >
                Ok
              </Button>
            </>
          }
          description="Your decision has been successfully submitted. Please note that the users payment will not be charged."
        />
      )}

      <DeniedUserPopup
        isLoading={isLoading}
        resolutionNote={resolutionNote}
        selectedRequest={selectedRequest}
        isOpenRequestDetailPopup={isOpenRequestDetailPopup}
        setResolutionNote={setResolutionNote}
        toggleRequestDetailPopup={toggleRequestDetailPopup}
        handleAddResolutionNote={handleAddResolutionNote}
      />
    </Box>
  );
};

export default ScoutAccess;
