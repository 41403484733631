import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { Logo } from "../assets/SVGs/Global";
import ReviewStep from "../components/registration/ReviewStep";
import ForgotPassword from "../components/registration/ForgotPassword";
import SetANewPassword from "../components/registration/SetNewPassword";

const SetANewPasswordPage: React.FC = () => {
  return (
    <Box h="100%" w="100%">
      <Box display="flex" h="100%" minH="100vh" overflow="hidden">
        <Box
          display={{ base: "none", xl: "block" }}
          w="35%"
          h="auto"
          backgroundImage="url('/images/Ragistration_bg.jpg')"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
        >
          <Box
            w="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Logo />
          </Box>
        </Box>
        <Box p={8} pb={0} w={{ base: "100%", xl: "65%" }}>
          <Box px={{ base: "0px", md: "50px", lg: "100px" }} py="50px">
            <Box>
              <SetANewPassword />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SetANewPasswordPage;
