import {
  border,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  NumberInput,
  NumberInputField,
  Select,
  SimpleGrid,
  Stack,
  useEditable,
} from "@chakra-ui/react";
import PasswordInput from "../common/PasswordInput";
import { Link } from "react-router-dom";
import { states } from "../../utils/constants";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addRegistrationDetails } from "../../store/registrationSlice";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { SetStateAction, useEffect, useState } from "react";
import { ChevronDownIcon, TriangleDownIcon } from "@chakra-ui/icons";
import flag1 from "../../assets/flags/tw.png";
import flag2 from "../../assets/flags/fr.png";
import flag3 from "../../assets/flags/nl.png";
import flag4 from "../../assets/flags/us.png";
import { RootState } from "../../store";
import { VerificationPayload } from "../../types/pages/registration";

interface RegistrationStepProps {
  handleNextStep: () => void;
  handleRoleSelection: (role: string) => void;
}

const country = [
  {
    country: "fr",
    flag: flag2,
    prefix: "+11",
  },
  {
    country: "es",
    flag: flag1,
    prefix: "+1",
  },
  {
    country: "us",
    flag: flag4,
    prefix: "+45",
  },
  {
    country: "nl",
    flag: flag3,
    prefix: "+25",
  },
];

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .matches(/^[A-Za-z]+$/, "First name should contain only alphabets")
    .required("Please enter first name"),
  initial: Yup.string().trim(),
  lastName: Yup.string()
    .trim()
    .matches(/^[A-Za-z]+$/, "Last name should contain only alphabets")
    .required("Please enter Last name"),
  phone: Yup.number()
    .typeError("Please enter Correct mobile number")
    .test(
      "length",
      "Please enter Correct mobile number",
      (val) => val?.toString()?.length === 10
    )
    .required("Please enter mobile number"),
  email: Yup.string()
    .trim()
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      "Please enter correct email"
    )
    .required("Please enter email address"),
  confirmEmail: Yup.string()
    .trim()
    .oneOf([Yup.ref("email")], "Please enter Confirm email same as email")
    .required("Please enter Confirm email same as email"),
  password: Yup.string().trim().required("Please enter password"),
  confirmPassword: Yup.string()
    .trim()
    .oneOf(
      [Yup.ref("password")],
      "Please enter Confirm password same as password"
    )
    .required("Please enter Confirm password same as password"),
  role: Yup.string().trim().required("Please select role"),
  addressLine1: Yup.string().trim().required("Please enter address"),
  addressLine2: Yup.string(),
  city: Yup.string().trim().required("Please enter city"),
  state: Yup.string().trim(),
  zipCode: Yup.string()
    .trim()
    .test("length", "Please enter correct zipcode", (val) => {
      return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(val?.toString() ?? "");
    })
    .required("Please enter zipcode"),
  isTACAccepted: Yup.boolean().oneOf(
    [true],
    "Please accept the terms and conditions"
  ),
  dob: Yup.date()
    .nullable()
    .required("Please select date of birth")
    .test("age", "You must be at least 18 years old", function (value) {
      const today = new Date();
      const ageLimit = new Date(today.setFullYear(today.getFullYear() - 18));
      return value && value <= ageLimit;
    }),
  gender: Yup.string(),
});

const RegistrationStep: React.FC<RegistrationStepProps> = ({
  handleNextStep,
  handleRoleSelection,
}) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const dispatch = useDispatch();
  const [number, setNumber] = useState(1);
  const [apiError, setApiError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedState, setSelectedState] = useState("Puerto Rico");
  const registrationData = useSelector(
    (state: RootState) => state.registration
  );

  const initialValues = {
    firstName: "",
    initial: "",
    lastName: "",
    phone: "",
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
    role: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "Puerto Rico",
    zipCode: "",
    isTACAccepted: false,
    gender: "",
    dob: null as Date | null,
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      // Construct the payload
      const payload = {
        firstName: values.firstName,
        lastName: values.lastName,
        initial: values.initial,
        telephoneNumber: `${country[number].prefix}${values.phone}`, // Combine country prefix and phone number
        gender: values.gender,
        dateOfBirth: values.dob?.toISOString().split("T")[0] || "", // Provide a default value of an empty string if dateOfBirth is undefined
        email: values.email,
        password: values.password,
        role: values.role,
        address1: values.addressLine1,
        address2: values.addressLine2,
        zipCode: values.zipCode,
        city: values.city,
        state: values.state,
      };

      // Dispatch to Redux store
      dispatch(
        addRegistrationDetails({
          key: "userInformation",
          data: { ...values },
        })
      );

      // Trigger the API call
      const success = await sendVerificationCode(payload);

      // Move to the next step
      if (success) {
        handleRoleSelection(values.role);
        handleNextStep();
      }
    },
  });
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    submitForm,
    setValues,
  } = formik;

  const [date, setDate] = useState(values.dob ?? new Date());

  const handleDateChange = (newDate: SetStateAction<Date>) => {
    setDate(newDate);
    setFieldValue("dob", newDate);
  };

  const handleInputChange = (event: {
    target: { value: string | number | Date };
  }) => {
    const inputDate = new Date(event.target.value);
    if (!isNaN(inputDate.getTime())) {
      handleDateChange(inputDate);
    }
  };

  useEffect(() => {
    if (registrationData.userInformation) {
      setValues((prevValues) => ({
        ...registrationData.userInformation,
        initial: registrationData.userInformation.initial ?? "",
        confirmEmail: registrationData.userInformation.confirmEmail ?? "",
        confirmPassword: registrationData.userInformation.confirmPassword ?? "",
        addressLine2: registrationData.userInformation.addressLine2 ?? "",
        state: registrationData.userInformation.state ?? "",
        gender: registrationData.userInformation.gender ?? "",
        isTACAccepted: prevValues.isTACAccepted,
      }));
    }
  }, [registrationData]);

  useEffect(() => {
    // Set the initial state value when the component mounts
    formik.setFieldValue("state", "Puerto Rico");
  }, []);

  const onMenuChange = (name: string, value: string) => {
    setFieldValue(name, value);
    if (name === "state") {
      setSelectedState(value);
    }
  };

  const sendVerificationCode = async (payload: VerificationPayload) => {
    setApiError(null);
    setIsLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/api/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        setIsLoading(false);
        const errorData = await response.json();
        if (response.status === 500) {
          // Set API error state if there is a 500 error
          setApiError(
            errorData.message || "An error occurred. Please try again."
          );
        }
        return false;
      }

      const data = await response.json();
      // save email to localStorage
      localStorage.setItem("statsEmail", values.email);
      console.log("Verification code sent:", data);
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
      setApiError("Network error. Please try again.");
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    setApiError(null);
  };

  console.log("values", values.phone);

  return (
    <Box pb={10}>
      <Heading
        as="h1"
        textAlign="left"
        fontSize={{ base: "30px", md: "45px" }}
        fontWeight="500"
        color="custom.blue"
        mb={8}
      >
        Registration
      </Heading>
      <Box
        mt={8}
        display="flex"
        flexDirection="column"
        gap={{ base: 4, md: 5 }}
        sx={{
          ".chakra-form-control": {
            ".chakra-input, .chakra-numberinput__field": {
              boxShadow: "none !important",
              background: "custom.bg",
              border: "none",
              fontWeight: "500",
              "&:focus": {
                border: "1px solid",
                borderColor: "custom.blue",
              },
              '&[aria-invalid="true"]': {
                border: "1px solid",
                borderColor: "custom.red",
              },
            },
            ".chakra-form__label": {
              marginBottom: "5px",
              color: "custom.gray2",
              ".chakra-form__required-indicator": {
                color: "custom.gray2",
              },
            },
            ".chakra-form__error-message": {
              marginTop: "5px",
              color: "custom.red",
            },
            ".chakra-menu__menu-button": {
              borderWidth: "1px",
              fontWeight: "500",
            },
            ".chakra-menu__menu-list": {
              ".chakra-menu__menuitem": {
                "&:hover": {
                  background: "custom.bg",
                },
                "&:focus": {
                  background: "custom.bg",
                },
              },
            },
          },
        }}
      >
        <Stack align="start" flexDirection={{ base: "column", md: "row" }}>
          <FormControl
            isRequired
            isInvalid={!!touched.firstName && !!errors.firstName}
          >
            <FormLabel>First name</FormLabel>
            <Input
              placeholder="John"
              sx={{ _placeholder: { color: "custom.gray2" } }}
              data-testId="firstName"
              name="firstName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
            />
            <FormErrorMessage>{errors.firstName}</FormErrorMessage>
          </FormControl>
          <FormControl pt={{ base: 4, md: "0px" }}>
            <FormLabel>Initial</FormLabel>
            <Input
              placeholder="A"
              sx={{ _placeholder: { color: "custom.gray2" } }}
              name="initial"
              data-testId="initial"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.initial}
            />
          </FormControl>
        </Stack>
        <Stack align="start" flexDirection={{ base: "column", md: "row" }}>
          <FormControl
            isRequired
            isInvalid={!!touched.lastName && !!errors.lastName}
            pt={{ base: 4, md: "0px" }}
          >
            <FormLabel>Last name</FormLabel>
            <Input
              placeholder="Doe"
              sx={{ _placeholder: { color: "custom.gray2" } }}
              name="lastName"
              data-testId="lastName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
            />
            <FormErrorMessage>{errors.lastName}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!touched.phone && !!errors.phone}>
            <FormLabel>Phone</FormLabel>
            <NumberInput
              display="flex"
              position="relative"
              zIndex={1}
              value={values.phone}
            >
              <Box>
                <Menu>
                  <MenuButton
                    as={Button}
                    w="100%"
                    textAlign="start"
                    rightIcon={
                      <TriangleDownIcon h="12px" w="12px" color="gray.500" />
                    }
                    _focus={{ bg: "custom.bg" }}
                    _hover={{ bg: "custom.bg", border: "none" }}
                    _active={{ bg: "custom.bg" }}
                    sx={{
                      backgroundColor: "custom.bg",
                      border: "none",
                      zIndex: "99999",
                      fontSize: "16px",
                      fontWeight: "400",
                      width: "90px",
                      height: "90%",
                      position: "absolute",
                      top: "2px",
                      left: "2px",
                    }}
                  >
                    <Box
                      cursor={"pointer"}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "6px",
                        alignItems: "center",
                      }}
                    >
                      <Box>{country[number].prefix}</Box>{" "}
                      <img
                        src={country[number].flag}
                        alt="  "
                        style={{ width: "18px", height: "15px" }}
                      />
                    </Box>
                  </MenuButton>
                  <MenuList>
                    {country.map((opt, index) => (
                      <MenuItem
                        minH="48px"
                        sx={{ display: "flex", gap: "5px" }}
                        key={index}
                        onClick={() => setNumber(index)}
                        _focus={{ bg: "custom.bg" }}
                        _hover={{ bg: "custom.bg" }}
                      >
                        {" "}
                        <Box>
                          {" "}
                          <img
                            src={opt.flag}
                            alt="  "
                            style={{ width: "20px", height: "20px" }}
                          />
                        </Box>
                        <Box>{opt.prefix}</Box>{" "}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Box>

              <NumberInputField
                placeholder="986-468-8432"
                sx={{ _placeholder: { color: "custom.gray2" } }}
                paddingStart="95px"
                name="phone"
                data-testId="phone"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </NumberInput>
            <FormErrorMessage>{errors.phone}</FormErrorMessage>
          </FormControl>
        </Stack>
        <Stack align="start" flexDirection={{ base: "column", md: "row" }}>
          <FormControl isInvalid={!!touched.email && !!errors.email}>
            <FormLabel>Gender</FormLabel>
            <Menu>
              <MenuButton
                data-testId="gender"
                as={Button}
                w="100%"
                textAlign="start"
                rightIcon={<ChevronDownIcon />}
                sx={{
                  border: "none",
                  backgroundColor: "custom.bg",
                  fontSize: "16px",
                  fontWeight: "400",
                  "&:hover": {
                    background: "custom.bg",
                    cursor: "context-menu",
                  },
                  "+ div": { width: "100%" },
                  "&:focus": { backgroundColor: "custom.bg" },
                }}
              >
                {values.gender === "" ? "Select gender" : values.gender}
              </MenuButton>
              <MenuList>
                <MenuItem
                  data-testId="male"
                  minH="48px"
                  onClick={() => onMenuChange("gender", "Male")}
                >
                  Male
                </MenuItem>
                <MenuItem
                  minH="48px"
                  onClick={() => onMenuChange("gender", "Female")}
                >
                  Female
                </MenuItem>
                <MenuItem
                  minH="40px"
                  onClick={() => onMenuChange("gender", "Other")}
                >
                  Other
                </MenuItem>
              </MenuList>
            </Menu>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={!!touched.dob && !!errors.dob}
            pt={{ base: 4, md: "0px" }}
          >
            <FormLabel>Date of birth</FormLabel>
            <Input
              data-testId="dob"
              type="date"
              value={date.toISOString().split("T")[0]}
              onChange={handleInputChange}
            />
            {/* <SingleDatepicker
              name="date-input"
              date={values.dob ?? new Date()}
              onDateChange={(date) => {
                setFieldValue("dob", date);
              }}
              propsConfigs={{
                dayOfMonthBtnProps: {
                  defaultBtnProps: {
                    paddingInline: 2,
                    height: 9,
                    width: 9,
                    color: "custom.black",
                    _hover: {
                      background: "custom.bg",
                    },
                  },
                  selectedBtnProps: {
                    background: "custom.blue",
                    color: "custom.white",
                    _hover: {
                      background: "custom.blue",
                    },
                  },
                },
                calendarPanelProps: {
                  contentProps: {
                    borderWidth: 0,
                  },
                  dividerProps: {
                    display: "none",
                  },
                },
                weekdayLabelProps: {
                  fontWeight: "normal",
                  color: "custom.gray2",
                },
              }}
            /> */}
            <FormErrorMessage>{errors.dob}</FormErrorMessage>
          </FormControl>
        </Stack>
        <Stack align="start" flexDirection={{ base: "column", md: "row" }}>
          <FormControl isRequired isInvalid={!!touched.email && !!errors.email}>
            <FormLabel>Email</FormLabel>
            <Input
              placeholder="john.doe@gmail.com"
              sx={{ _placeholder: { color: "custom.gray2" } }}
              name="email"
              data-testId="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            <FormErrorMessage>{errors.email}</FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={!!touched.confirmEmail && !!errors.confirmEmail}
            pt={{ base: 4, md: "0px" }}
          >
            <FormLabel>Confirm Email</FormLabel>
            <Input
              placeholder="Type email again"
              sx={{ _placeholder: { color: "custom.gray2" } }}
              name="confirmEmail"
              data-testId="confirmEmail"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmEmail}
            />
            <FormErrorMessage>{errors.confirmEmail}</FormErrorMessage>
          </FormControl>
        </Stack>
        <Stack align="start" flexDirection={{ base: "column", md: "row" }}>
          <FormControl
            isRequired
            isInvalid={!!touched.password && !!errors.password}
          >
            <FormLabel>Password</FormLabel>
            <PasswordInput
              placeholder="*****"
              sx={{ _placeholder: { color: "custom.gray2" } }}
              name="password"
              data-testId="password"
              onChange={handlePasswordChange}
              onBlur={handleBlur}
              value={values.password}
            />
            <FormErrorMessage>{errors.password}</FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={!!touched.confirmPassword && !!errors.confirmPassword}
            pt={{ base: 4, md: "0px" }}
          >
            <FormLabel>Confirm Password</FormLabel>
            <PasswordInput
              placeholder="Type password again"
              sx={{ _placeholder: { color: "custom.gray2" } }}
              name="confirmPassword"
              data-testId="confirmPassword"
              onChange={handlePasswordChange}
              onBlur={handleBlur}
              value={values.confirmPassword}
            />
            <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
          </FormControl>
        </Stack>
        <Stack>
          <FormControl isRequired isInvalid={!!touched.role && !!errors.role}>
            <FormLabel>Role</FormLabel>
            <Menu>
              <MenuButton
                data-testId="role"
                as={Button}
                w="100%"
                textAlign="start"
                rightIcon={<ChevronDownIcon />}
                _active={{ bg: "custom.bg" }}
                _hover={{ bg: "custom.bg" }}
                _focus={{ bg: "custom.bg" }}
                sx={{
                  backgroundColor: "custom.bg",
                  fontSize: "16px",
                  fontWeight: "400",
                  "+ div": { width: "100%" },
                  border:
                    !!touched.role && !!errors.role ? "1px solid" : "none",
                  borderColor:
                    !!touched.role && !!errors.role ? "custom.red" : "none",
                }}
              >
                {values.role === "" ? "Select role" : values.role}
              </MenuButton>
              <MenuList>
                <MenuItem
                  data-testId="guardian"
                  minH="48px"
                  onClick={() => onMenuChange("role", "Guardian")}
                >
                  Guardian
                </MenuItem>
                <MenuItem
                  data-testId="scout"
                  minH="48px"
                  onClick={() => onMenuChange("role", "Scout")}
                >
                  Scout
                </MenuItem>
              </MenuList>
            </Menu>
            <FormErrorMessage>{errors.role}</FormErrorMessage>
          </FormControl>
        </Stack>
        <Stack align="start" flexDirection={{ base: "column", md: "row" }}>
          <FormControl
            isRequired
            isInvalid={!!touched.addressLine1 && !!errors.addressLine1}
          >
            <FormLabel>Address 1</FormLabel>
            <Input
              data-testId="address1"
              placeholder="22 Packet Square"
              sx={{ _placeholder: { color: "custom.gray2" } }}
              name="addressLine1"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.addressLine1}
            />
            <FormErrorMessage>{errors.addressLine1}</FormErrorMessage>
          </FormControl>
          <FormControl>
            <FormLabel>Address 2</FormLabel>
            <Input
              placeholder="Suite 23"
              sx={{ _placeholder: { color: "custom.gray2" } }}
              name="addressLine2"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.addressLine2}
            />
          </FormControl>
        </Stack>
        <Stack align="start" flexDirection={{ base: "column", md: "row" }}>
          <FormControl
            isRequired
            isInvalid={!!touched.zipCode && !!errors.zipCode}
            pt={{ base: 4, md: "0px" }}
          >
            <FormLabel>Zip Code</FormLabel>
            <Input
              data-testId="zipCode"
              placeholder="00901"
              sx={{ _placeholder: { color: "custom.gray2" } }}
              name="zipCode"
              onBlur={handleBlur}
              onChange={handleChange}
              bgColor={"custom.bg"}
              value={values.zipCode}
            />
            <FormErrorMessage>{errors.zipCode}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!touched.city && !!errors.city}>
            <FormLabel>City</FormLabel>
            <Input
              data-testId="city"
              placeholder="San Juan"
              sx={{ _placeholder: { color: "custom.gray2" } }}
              name="city"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.city}
              bgColor="custom.bg"
            />
            <FormErrorMessage>{errors.city}</FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={!!touched.state && !!errors.state}
            pt={{ base: 4, md: "0px" }}
          >
            <FormLabel>State</FormLabel>
            <Menu>
              <MenuButton
                as={Button}
                w="100%"
                textAlign="start"
                rightIcon={<ChevronDownIcon />}
                _hover={{ bg: "custom.bg" }}
                _focus={{ bg: "custom.bg" }}
                sx={{
                  border: "none",
                  backgroundColor: "custom.bg",
                  fontSize: "16px",
                  fontWeight: "400",
                  "+ div": { width: "100%" },
                }}
              >
                {values.state === "" ? "Select state" : values.state}
              </MenuButton>
              <MenuList sx={{ maxH: "250px", overflow: "auto" }}>
                {states.map((opt, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => onMenuChange("state", opt.label)}
                  >
                    {opt.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </FormControl>
        </Stack>

        <Stack>
          <FormControl
            isInvalid={!!touched.isTACAccepted && !!errors.isTACAccepted}
            width={{ base: "100%", lg: "75%" }}
            sx={{ a: { color: "custom.blue1" } }}
          >
            <Checkbox
              data-testId="TAC"
              name="isTACAccepted"
              onChange={handleChange}
              isChecked={values.isTACAccepted}
              size="lg"
              alignItems="start"
              sx={{
                ".chakra-checkbox__control": {
                  marginTop: "5px",
                  "&[data-checked],&[data-checked]:hover": {
                    background: "custom.blue",
                    borderColor: "custom.blue",
                  },
                },
              }}
            >
              I'd like to receive more information about company, understand and
              agree to the{" "}
              <a
                href="https://cdn.prod.website-files.com/669ad9fffb20abdbb291b6f5/670456eccc72fc72bf21a987_Sample-Terms-and-Conditions-Template_PDF-4.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </Checkbox>
            <FormErrorMessage mt={0}>{errors.isTACAccepted}</FormErrorMessage>
          </FormControl>
          {apiError && (
            <Box color="red.500" textAlign="center" my={4}>
              {apiError}
            </Box>
          )}
        </Stack>

        <Box
          display="flex"
          justifyContent={{ base: "space-between", md: "end" }}
          gap={7}
          mt={5}
        >
          <Box display="flex" justifyContent={{ base: "center", md: "end" }}>
            <Button
              onClick={() => window.history.back()}
              _active={{ bg: "custom.white" }}
              _hover={{ bg: "custom.bg" }}
              backgroundColor="custom.white"
              color="custom.grayIcon"
              border="1px solid"
              borderColor="custom.grayIcon"
              width={{ base: "130px", md: "150px" }}
              px={8}
            >
              Back
            </Button>
          </Box>
          <Box display="flex" justifyContent={{ base: "center", md: "end" }}>
            <Button
              isLoading={isLoading}
              backgroundColor="custom.blue"
              color="custom.white"
              width={{ base: "130px", md: "150px" }}
              _hover={{ bg: "custom.blue" }}
              onClick={submitForm}
              px={8}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RegistrationStep;
