// src/components/Shared/WizardButton.tsx

import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

interface WizardButtonProps extends ButtonProps {
  variant: 'back' | 'cancel' |'next' | 'submit' | 'registrate';
}

const WizardButton: React.FC<WizardButtonProps> = ({ variant, children, ...rest }) => {
  // Definir altura y ancho fijos
  const BUTTON_HEIGHT = '30px';
  const BUTTON_WIDTH = '120px';

  if (variant === 'back') {
    return (
      <Button
        bg="white"
        color="gray.700"
        border="2px solid"
        borderColor="gray.700"
        borderRadius="md" // Forma más rectangular
        height={BUTTON_HEIGHT}
        width={BUTTON_WIDTH}
        _hover={{ bg: 'gray.100', borderColor: 'gray.700' }}
        {...rest}
      >
        {children}
      </Button>
    );
  }

  if (variant === 'cancel') {
    return (
      <Button
        bg="white"
        color="gray.700"
        border="2px solid"
        borderColor="gray.700"
        borderRadius="md" // Forma más rectangular
        height={BUTTON_HEIGHT}
        width={BUTTON_WIDTH}
        _hover={{ bg: 'gray.100', borderColor: 'gray.700' }}
        {...rest}
      >
        {children}
      </Button>
    );
  }

  if (variant === 'next') {
    return (
      <Button
        bg="#09264a" // Color índigo en hex
        color="white"
        border="2px solid"
        borderColor="#09264a"
        borderRadius="md" // Forma más rectangular
        height={BUTTON_HEIGHT}
        width={BUTTON_WIDTH}
        _hover={{ bg: '#09264a', borderColor: '#09264a' }}
        {...rest}
      >
        {children}
      </Button>
    );
  }

  if (variant === 'submit') {
    return (
      <Button
        bg="#09264a" // Color índigo en hex
        color="white"
        border="2px solid"
        borderColor="#09264a"
        borderRadius="md" // Forma más rectangular
        height={BUTTON_HEIGHT}
        width={BUTTON_WIDTH}
        _hover={{ bg: '#09264a', borderColor: '#09264a' }}
        {...rest}
      >
        {children}
      </Button>
    );
  }


  if (variant === 'registrate') {
    return (
      <Button
        bg="#09264a" // Color índigo en hex
        color="white"
        border="2px solid"
        borderColor="#09264a"
        borderRadius="md" // Forma más rectangular
        height={BUTTON_HEIGHT}
        width={BUTTON_WIDTH}
        _hover={{ bg: '#09264a', borderColor: '#09264a' }}
        {...rest}
      >
        {children}
      </Button>
    );
  }

  // Fallback en caso de variante no especificada
  return (
    <Button
      height={BUTTON_HEIGHT}
      width={BUTTON_WIDTH}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default WizardButton;
