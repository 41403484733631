import {
  Flex,
  Text,
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { Dispatch, FC } from "react";
import flag3 from "../../assets/flags/nl.png";
import CustomPopup from "../common/CustomPopup";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { ApplePay, DeleteIcon, GooglePay } from "../../assets/SVGs/Global";

interface PaymentPopupProps {
  setIsPaymentPopupOpen: Dispatch<React.SetStateAction<boolean>>;
  isPaymentPopupOpen: boolean;
}

const PaymentPopup: FC<PaymentPopupProps> = ({
  isPaymentPopupOpen,
  setIsPaymentPopupOpen,
}) => {
  return (
    <CustomPopup
      onClose={() => setIsPaymentPopupOpen(false)}
      isOpen={isPaymentPopupOpen}
      bodyElements={
        <Flex
          alignItems="center"
          direction={"column"}
          w={"full"}
          gap={{ base: 2, md: 4, xl: 8 }}
          mt={3}
        >
          <Flex
            w={"full"}
            alignItems="center"
            gap={{ base: 2, md: 4, xl: 8 }}
            direction={{ base: "column", md: "row" }}
          >
            <FormControl id="cardNumber">
              <FormLabel color={"custom.gray2"}>Card Number*</FormLabel>
              <Input
                type="number"
                // value="**** **** **** "
                bg={"custom.bg"}
                h={12}
                position={"relative"}
                sx={{ _placeholder: { color: "custom.gray2" } }}
                border={"none"}
                color={"custom.gray2"}
                required
              />
              <Box
                position={"absolute"}
                top={"44px"}
                right={4}
                h={6}
                w={8}
                zIndex={"50"}
              >
                <img src={flag3} alt="" />
              </Box>
            </FormControl>
          </Flex>
          <Flex
            w={"full"}
            alignItems="center"
            gap={{ base: 2, md: 4, xl: 8 }}
            direction={{ base: "column", md: "row" }}
          >
            <FormControl id="expiration">
              <FormLabel color={"custom.gray2"}>Expiration*</FormLabel>
              <Input
                type="text"
                value="05/2028"
                sx={{ _placeholder: { color: "custom.gray2" } }}
                bg={"custom.bg"}
                border={"none"}
                h={12}
                color={"custom.gray2"}
                required
              />
            </FormControl>
            <FormControl id="CVC">
              <FormLabel color={"custom.gray2"}>CVC*</FormLabel>
              <Input
                type="number"
                value="874"
                h={12}
                bg={"custom.bg"}
                sx={{ _placeholder: { color: "custom.gray2" } }}
                border={"none"}
                color={"custom.gray2"}
                required
              />
            </FormControl>
          </Flex>
          <Flex
            w={"full"}
            alignItems="center"
            gap={{ base: 2, md: 4, xl: 8 }}
            direction={{ base: "column", md: "row" }}
          >
            <FormControl id="country">
              <FormLabel color={"custom.gray2"}>Country*</FormLabel>
              <Menu>
                <MenuButton
                  as={Button}
                  w="100%"
                  h={12}
                  textAlign="start"
                  _active={{ bg: "custom.bg" }}
                  rightIcon={<ChevronDownIcon />}
                  sx={{
                    border: "none",
                    backgroundColor: "custom.bg",
                    fontSize: "16px",
                    fontWeight: "400",
                    "&:hover": {
                      background: "custom.bg",
                    },
                    "+ div": { width: "100%" },
                    "&:focus": { backgroundColor: "custom.bg" },
                  }}
                >
                  {"USA"}
                </MenuButton>
                <MenuList>
                  <MenuItem
                    minH="48px"
                    _hover={{ bg: "custom.bg" }}
                    _focus={{ bg: "custom.bg" }}
                  >
                    Country 1
                  </MenuItem>
                  <MenuItem
                    minH="48px"
                    _hover={{ bg: "custom.bg" }}
                    _focus={{ bg: "custom.bg" }}
                  >
                    Country 2
                  </MenuItem>
                  <MenuItem
                    minH="40px"
                    _focus={{ bg: "custom.bg" }}
                    _hover={{ bg: "custom.bg" }}
                  >
                    Country 3
                  </MenuItem>
                </MenuList>
              </Menu>
            </FormControl>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent={"center"}
            gap={4}
            w={"full"}
          >
            <hr style={{ width: "30%", background: "#d0d0e1" }} />
            <Text color={"custom.gray1"}>or</Text>
            <hr style={{ width: "30%", background: "#d0d0e1" }} />
          </Flex>
          <Flex w={"full"} alignItems="center" gap={{ base: 2, md: 4, xl: 8 }}>
            <Button
              bg="custom.black"
              h={12}
              _hover={{ bg: "custom.black", opacity: 0.8 }}
              w={"50%"}
            >
              <ApplePay />
            </Button>
            <Button
              bg="custom.blue"
              h={12}
              _hover={{ bg: "custom.blue", opacity: 0.8 }}
              w={"50%"}
            >
              <GooglePay />
            </Button>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent={"space-between"}
            gap={4}
            w={"full"}
          >
            <Text color={"custom.black"} fontSize={"20px"} fontWeight={600}>
              Premium Tier
            </Text>
            <Box color={"custom.gray2"}>
              {" "}
              <Text>
                {" "}
                <span
                  style={{
                    color: "#232327",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  $34.99 /
                </span>{" "}
                per year{" "}
              </Text>
            </Box>
          </Flex>
        </Flex>
      }
      footerElement={
        <>
          <Button
            variant="ghost"
            bg={"#F052522f"}
            _hover={{ bg: "#F0525250" }}
            borderColor={"custom.grayIcon"}
            h={12}
            w={12}
            p={0}
            mr={"auto"}
            onClick={() => setIsPaymentPopupOpen(false)}
          >
            {" "}
            <DeleteIcon />
          </Button>

          <Button
            variant="outline"
            _hover={{ bg: "custom.bg" }}
            borderColor={"custom.grayIcon"}
            h={12}
            mr={3}
            onClick={() => setIsPaymentPopupOpen(false)}
            minW={{ sm: "40%", md: "200px" }}
          >
            Cancel
          </Button>
          <Button
            bg="custom.blue"
            _hover={{ bg: "custom.blue", opacity: 0.8 }}
            h={12}
            minW={{ sm: "40%", md: "200px" }}
            color={"custom.white"}
          >
            Save
          </Button>
        </>
      }
      cancelButtonText="Cancel"
      saveButtonText="Save Changes"
      titleText={<Text>Edit Payment Method</Text>}
    />
  );
};

export default PaymentPopup;
