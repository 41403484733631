import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Grid,
  Center,
  Spinner,
  Text,
  Badge,
  Heading,
  Flex,
  InputGroup,
  Input,
  InputLeftElement,
  Button,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  VStack,
} from '@chakra-ui/react';
import { FaSearch, FaShoppingBag, FaUserPlus, FaEllipsisH, FaTimes, FaShareAlt, FaRedoAlt, FaChartBar } from 'react-icons/fa';
import axiosInstance from '../api/axiosConfig';
import PlaceholderCard from '../components/players_registry/PlaceholderCard';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from "@chakra-ui/icons";
import BuySpotsModal from '../components/players_registry/BuySpotModal';

interface Player {
  FIRST_NAME: string;
  LAST_NAME: string;
  PLAYING_POSITIONS: string;
  PLAYER_ID: string;
}

const MAX_SPOTS = 99;

const MyRoster: React.FC = () => {
  const [players, setPlayers] = useState<Player[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [popoverOpen, setPopoverOpen] = useState<number | null>(null);
  const navigate = useNavigate();

  const waifCount = 0;
  const officialCount = players.length;
  const availableSpots = 2;

  useEffect(() => {
    const fetchPlayers = async () => {
      
      try {
        const storedGuardian = JSON.parse(localStorage.getItem('user') || '{}');
        const guardianEmail = storedGuardian?.email;

        if (!guardianEmail) {
          console.error('No guardian email found in local storage.');
          setLoading(false);
          return;
        }

        const response = await axiosInstance.get<{ success: boolean; players: Player[] }>('/players/by-guardian', {
          params: { guardianEmail },
        });

        if (response.data.success) {
          setPlayers(response.data.players);
        } else {
          console.error('Error in response:', response.data);
        }
      } catch (error) {
        console.error('Error fetching players:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlayers();
  }, []);

  const filteredPlayers = useMemo(() => {
    const lowercasedTerm = searchTerm.trim().toLowerCase();
    return players.filter((player) => {
      const fullName = `${player.FIRST_NAME} ${player.LAST_NAME}`.toLowerCase();
      const positions = player.PLAYING_POSITIONS.toLowerCase();
      const playerID = player.PLAYER_ID.toLowerCase();
      return (
        fullName.includes(lowercasedTerm) ||
        positions.includes(lowercasedTerm) ||
        playerID.includes(lowercasedTerm)
      );
    });
  }, [players, searchTerm]);

  const [isBuySpotsModalOpen, setBuySpotsModalOpen] = useState(false);
  const openBuySpotsModal = () => setBuySpotsModalOpen(true);
  const closeBuySpotsModal = () => setBuySpotsModalOpen(false);

  return (
    <>
      <Box width="100%" maxWidth="1125px" mx="auto" p={2} minHeight="100vh" >
        <Flex gap={2} alignItems={"center"} mb={{ base: 3, md: 7 }}>
          <Button background={"#fff"} onClick={() => navigate("/players")} width={"40px"}>
            <ChevronLeftIcon fontSize={"3xl"} />
          </Button>
          <Heading as="h3" fontSize={{ base: 18, md: 24, lg: 30, xl: "3xl" }}>
            {"My Roster"}
          </Heading>
        </Flex>

        {/* Nueva cuadrícula para la barra de búsqueda, contadores y botón */}
        <Grid
          templateColumns={{
            base: "1fr",
            sm: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)"
          }}          
          gap={{ base: 4, md: 6 }}
          justifyItems="center"
          justifyContent="center"
          mt={6}
          width="353px"
          mx="auto"
        >
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <FaSearch color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Search players"
              bg="white"
              width="353px"
              border="1px solid gray"
              borderRadius="md"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>

          <Center>
            <Flex>
              <Box border="1px solid gray" px={6} py={2} width="131px" color="#1A365D" bg="white" display="flex" alignItems="center" justifyContent="center" borderTopLeftRadius="md" borderBottomLeftRadius="md" borderRight="none">
                Available
                <Badge ml={2} borderRadius="full" px={2} bg="blue.900" color="white">{availableSpots}</Badge>
              </Box>
              <Box border="1px solid gray" px={6} py={2} width="100px" color="#1A365D" bg="white" display="flex" alignItems="center" justifyContent="center" borderLeft="none" borderRight="none">
                Waif
                <Badge ml={2} borderRadius="full" px={2} bg="blue.900" color="white">{waifCount}</Badge>
              </Box>
              <Box border="1px solid gray" px={6} py={2} width="122px" color="#1A365D" bg="white" display="flex" alignItems="center" justifyContent="center" borderTopRightRadius="md" borderBottomRightRadius="md" borderLeft="none">
                Official
                <Badge ml={2} borderRadius="full" px={2} bg="blue.900" color="white">{officialCount}</Badge>
              </Box>
            </Flex>
          </Center>

          <Box width="353px" display="flex" justifyContent="flex-end">
            <Button
              bg="#1A365D"
              color="gray.100"
              border="none"
              leftIcon={<FaShoppingBag />}
              _hover={{ bg: 'blue.900', opacity: 0.8 }}
              onClick={openBuySpotsModal}
              width="200px"
            >
              Buy Spots
            </Button>
          </Box>

        </Grid>

        {loading ? (
          <Center mt={10}>
            <Spinner size="xl" color="blue.500" />
          </Center>
        ) : (
          <Box display="flex" justifyContent="center" width="100%" mt="-18px" p={2}>
            <Grid
              templateColumns={{
                base: "1fr",
                sm: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)"
              }}
              gap={{ base: 4, md: 6 }}
              justifyItems="center"
              justifyContent="center"
              mt={6}
              width="353px"
              mx="auto"
            >
              {filteredPlayers.map((player, index) => (
                <Box
                  key={index}
                  bg="white"
                  p={2}
                  borderRadius="xl"
                  boxShadow="lg"
                  border="1px solid #D0D0E1"
                  height="472px"
                  width="353px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  position="relative"
                >
                  <PlaceholderCard
                    playerName={`${player.FIRST_NAME} ${player.LAST_NAME}`}
                    playerPositions={player.PLAYING_POSITIONS.split(', ').map(pos => pos.split(' - ')[0]).join(', ')}
                    playerID={player.PLAYER_ID}
                  />
                  <Popover isOpen={popoverOpen === index} onClose={() => setPopoverOpen(null)} placement="top-end">
                    <PopoverTrigger>
                      <IconButton
                        icon={popoverOpen === index ? <FaTimes /> : <FaEllipsisH />}
                        aria-label="Options"
                        borderRadius="full"
                        color="white"
                        backgroundColor="#09264a"
                        position="absolute"
                        bottom="15px"
                        right="16px"
                        _hover={{ backgroundColor: "blue.800" }}
                        w="42px"
                        h="42px"
                        fontSize="20px"
                        onClick={() => setPopoverOpen(popoverOpen === index ? null : index)}
                      />
                    </PopoverTrigger>
                    <PopoverContent width="auto" bg="transparent" boxShadow="none" _focus={{ boxShadow: "none" }}>
                      <VStack spacing={2} alignItems="center" position="absolute" bottom="3px" right="1px">
                        <IconButton icon={<FaShareAlt />} aria-label="Share" borderRadius="full" color="#09264a" backgroundColor="white" border="1px solid #09264a" w="36px" h="36px" fontSize="15px" _hover={{ backgroundColor: "#E2E8F0" }} />
                        <IconButton icon={<FaRedoAlt />} aria-label="Rotate Card" borderRadius="full" color="#09264a" backgroundColor="white" border="1px solid #09264a" w="36px" h="36px" fontSize="15px" _hover={{ backgroundColor: "#E2E8F0" }} />
                        <IconButton icon={<FaChartBar />} aria-label="Statistics" borderRadius="full" color="#09264a" backgroundColor="white" border="1px solid #09264a" w="36px" h="36px" fontSize="15px" _hover={{ backgroundColor: "#E2E8F0" }} />
                      </VStack>
                    </PopoverContent>
                  </Popover>
                </Box>
              ))}
              {[1, 2].map((_, index) => (
                <Box
                  key={index}
                  bg="white"
                  p={2}
                  borderRadius="md"
                  boxShadow="lg"
                  border="1px solid gray"
                  height="477px"
                  width="353px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => navigate('/create-player')}
                >
                  <Box bg="#1A365D" _hover={{ backgroundColor: "blue.800" }} cursor="pointer" color="white" height="220px" width="220px" display="flex" flexDirection="column" justifyContent="center" alignItems="center" borderRadius="md">
                    <FaUserPlus size="80px" />
                    <Text fontSize="lg" mt={4} textAlign="center">Add Players</Text>
                  </Box>
                </Box>
              ))}
            </Grid>
          </Box>
        )}
        <BuySpotsModal isOpen={isBuySpotsModalOpen} onClose={closeBuySpotsModal} availableSpots={99 - waifCount - officialCount - availableSpots} />
      </Box>
    </>
  );
};

export default MyRoster;
