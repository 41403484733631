import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
    fonts: {
        heading: `'Montserrat', sans-serif`,
        body: `'Montserrat', sans-serif`,
    },
    colors: {
        custom: {
            blue: "#1B154B",
            blue1: "#31BFEE",
            white: "#FFFFFF",
            bg: "#F2F2F2",
            black: "#232327",
            gray1: "#797987",
            gray2: "#959595",
            gray3: "#757575",
            grayIcon: "#545560",
            red: "#F05252",
            outline: "#d0d0e1",
        },
    },
    components: {
        Table: {
            variants: {
                striped: {
                    th: {
                        bg: '#f2f2f2',
                    },
                    tr: {
                        '&:nth-of-type(even)': {
                            bg: '#f2f2f2',
                        },
                        '&:nth-of-type(odd)': {
                            bg: '#ffffff',
                        },
                    },
                },
            },
        },
    },
})

export default theme