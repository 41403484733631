import {
  border,
  Box,
  Button,
  Checkbox,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  NumberInput,
  NumberInputField,
  Select,
  Text,
} from "@chakra-ui/react";
import PasswordInput from "../common/PasswordInput";
import { Link, useNavigate } from "react-router-dom";
import { plansPrice, states } from "../../utils/constants";
import * as Yup from "yup";
import { useFormik, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { planType } from "../../types/pages/registration";
import {
  clearRegistrationData,
  sendManagerEmail,
} from "../../store/registrationSlice";
import { saveScout } from "../../store/authSlice";
import { sendGuardianFreeEmail, sendScoutEmail } from "../../store/authSlice";

const planLabel = {
  BASIC: "Free",
  PREMIUM: "Premium",
  ALL_FEATURES: "All features",
};

interface ReviewStepProps {
  handleNextStep: () => void;
  handlePreviousStep: () => void;
}

const ReviewStep: React.FC<ReviewStepProps> = ({
  handleNextStep,
  handlePreviousStep,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const registrationData = useSelector(
    (state: RootState) => state.registration
  );

  console.log(registrationData.subscriptionType);
  const isPaidPlan =
    plansPrice[registrationData.subscriptionType as planType] !== 0;
  const isScout = registrationData.userInformation.role === "Scout";
  console.log("!isPaidPlan", !isPaidPlan);
  const formik = useFormik({
    initialValues: {
      firstName: registrationData.userInformation.firstName,
      initial: registrationData.userInformation.initial || "",
      lastName: registrationData.userInformation.lastName,
      phone: registrationData.userInformation.phone,
      email: registrationData.userInformation.email,
      addressLine1: registrationData.userInformation.addressLine1,
      addressLine2: registrationData.userInformation.addressLine2 || "",
      city: registrationData.userInformation.city,
      state: registrationData.userInformation.state,
      zipCode: registrationData.userInformation.zipCode,
      role: registrationData.userInformation.role,
      password: registrationData.userInformation.password,
    },
    onSubmit: async (values) => {
      console.log("submit triggered.");
      console.log("Submitted values", values);
      console.log("Redux registrationData", registrationData);
      if (!isPaidPlan || isScout) {
        if (isScout) {
          // Send the email to the manager here
          if (registrationData.userInformation.role === "Scout") {
            await dispatch(
              saveScout({ userInformation: registrationData.userInformation })
            ).unwrap();
            console.log("Sending email to the scout");
            try {
              const result = await dispatch(
                sendScoutEmail({
                  email: registrationData.userInformation.email,
                  firstName: registrationData.userInformation.firstName,
                })
              ).unwrap();
              console.log("Email sent to Scout successfully:", result);
            } catch (error) {
              console.error("Failed to send email:", error);
              // Handle the error (e.g., show an error message to the user)
            }
            console.log("Sending email to the manager");
            try {
              const result = await dispatch(
                sendManagerEmail({
                  email: registrationData.userInformation.email,
                  name: `${registrationData.userInformation.firstName} ${registrationData.userInformation.lastName}`,
                })
              ).unwrap();
              console.log("Email sent to Manager successfully:", result);
            } catch (error) {
              console.error("Failed to send email:", error);
              // Handle the error (e.g., show an error message to the user)
            }
          }
          dispatch(clearRegistrationData());
          navigate("/?role=scout");
        } else {
          // Send email if the user is a Guardian on the free plan
          dispatch(
            sendGuardianFreeEmail({
              email: registrationData.userInformation.email,
              firstName: registrationData.userInformation.firstName,
            })
          );
          dispatch(clearRegistrationData());
          navigate("/?success=true");
        }
        resetForm();
      } else {
        handleNextStep();
      }
    },
  });
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    submitForm,
    resetForm,
  } = formik;

  console.log(
    registrationData.userInformation.role === "Guardian" &&
      plansPrice[registrationData.subscriptionType as planType] !== 0
  );
  return (
    <Box>
      <Heading as="h1" size="lg" textAlign="center">
        Review
      </Heading>
      <Box mt={8} display="flex" flexDirection="column" gap={5}>
        <form onSubmit={formik.handleSubmit}>
          <HStack align="start" spacing={10}>
            <FormControl isInvalid={!!touched.firstName && !!errors.firstName}>
              <FormLabel pt="4px" minWidth="100px">
                First name
              </FormLabel>
              <Editable
                value={registrationData.userInformation.firstName}
                isDisabled
                defaultValue="John"
                color="gray.500"
              >
                <EditablePreview />
                <EditableInput
                  style={{ boxShadow: "none" }}
                  border="1px"
                  borderColor="gray.300"
                  pl="12px"
                  minWidth="250px"
                />
              </Editable>
              {/* <Editable defaultValue='John' color='gray.500'>
                            <EditablePreview />
                            <EditableInput style={{ boxShadow: 'none' }} border='1px' borderColor='gray.300' pl='12px' minWidth='250px' />
                        </Editable> */}
            </FormControl>
            <FormControl>
              <FormLabel pt="4px" minWidth="100px">
                Initial
              </FormLabel>
              <Editable
                value={registrationData.userInformation.initial || ""}
                isDisabled
                color="gray.500"
              >
                <EditablePreview />
                <EditableInput
                  style={{ boxShadow: "none" }}
                  border="1px"
                  borderColor="gray.300"
                  pl="12px"
                  minWidth="250px"
                />
              </Editable>
            </FormControl>
            <FormControl isInvalid={!!touched.lastName && !!errors.lastName}>
              <FormLabel pt="4px" minWidth="100px">
                Last name
              </FormLabel>
              <Editable
                value={registrationData.userInformation.lastName}
                isDisabled
                defaultValue="Doe"
                color="gray.500"
              >
                <EditablePreview />
                <EditableInput
                  style={{ boxShadow: "none" }}
                  border="1px"
                  borderColor="gray.300"
                  pl="12px"
                  minWidth="250px"
                />
              </Editable>
            </FormControl>
          </HStack>
          <HStack align="start" spacing={10}>
            <FormControl isInvalid={!!touched.phone && !!errors.phone}>
              <FormLabel pt="4px" minWidth="100px">
                Phone
              </FormLabel>
              <Editable
                value={registrationData.userInformation.phone}
                isDisabled
                defaultValue="986-468-8432"
                color="gray.500"
              >
                <EditablePreview />
                <EditableInput
                  style={{ boxShadow: "none" }}
                  border="1px"
                  borderColor="gray.300"
                  pl="12px"
                  minWidth="250px"
                />
              </Editable>
            </FormControl>
            <FormControl isInvalid={!!touched.role && !!errors.role}>
              <FormLabel pt="4px" minWidth="100px">
                Role
              </FormLabel>
              <Editable
                value={registrationData.userInformation.role}
                isDisabled
                defaultValue="Guardian"
                color="gray.500"
              >
                <EditablePreview />
                <EditableInput
                  style={{ boxShadow: "none" }}
                  border="1px"
                  borderColor="gray.300"
                  pl="12px"
                  minWidth="250px"
                />
              </Editable>
            </FormControl>
            <FormControl isInvalid={!!touched.password && !!errors.password}>
              <FormLabel minWidth="100px">Password</FormLabel>
              <Text color="gray.500" pt="2px">
                *****
              </Text>
            </FormControl>
          </HStack>
          <HStack align="start" spacing={10}>
            <FormControl isInvalid={!!touched.email && !!errors.email}>
              <FormLabel minWidth="100px">Email</FormLabel>
              <Text color="gray.500">
                {registrationData.userInformation.email}
              </Text>
            </FormControl>
            <FormControl
              isInvalid={!!touched.addressLine1 && !!errors.addressLine1}
            >
              <FormLabel pt="4px" minWidth="100px">
                Address 1
              </FormLabel>
              <Editable
                value={registrationData.userInformation.addressLine1}
                isDisabled
                defaultValue="22 Packet Square"
                color="gray.500"
              >
                <EditablePreview />
                <EditableInput
                  style={{ boxShadow: "none" }}
                  border="1px"
                  borderColor="gray.300"
                  pl="12px"
                  minWidth="250px"
                />
              </Editable>
            </FormControl>
            <FormControl>
              <FormLabel pt="4px" minWidth="100px">
                Address 2
              </FormLabel>
              <Editable
                value={registrationData.userInformation.addressLine2}
                isDisabled
                // defaultValue="Suite 23"
                color="gray.500"
              >
                <EditablePreview />
                <EditableInput
                  style={{ boxShadow: "none" }}
                  border="1px"
                  borderColor="gray.300"
                  pl="12px"
                  minWidth="250px"
                />
              </Editable>
            </FormControl>
          </HStack>
          <HStack align="start" spacing={10}>
            <FormControl isInvalid={!!touched.city && !!errors.city}>
              <FormLabel pt="4px" minWidth="100px">
                City
              </FormLabel>
              <Editable
                value={registrationData.userInformation.city}
                isDisabled
                defaultValue="San Juan"
                color="gray.500"
              >
                <EditablePreview />
                <EditableInput
                  style={{ boxShadow: "none" }}
                  border="1px"
                  borderColor="gray.300"
                  pl="12px"
                  minWidth="250px"
                />
              </Editable>
            </FormControl>
            <FormControl>
              <FormLabel pt="4px" minWidth="100px">
                State
              </FormLabel>
              <Editable
                value={registrationData.userInformation.state}
                isDisabled
                defaultValue="Puerto Rico"
                color="gray.500"
              >
                <EditablePreview />
                <EditableInput
                  style={{ boxShadow: "none" }}
                  border="1px"
                  borderColor="gray.300"
                  pl="12px"
                  minWidth="250px"
                />
              </Editable>
            </FormControl>
            <FormControl isInvalid={!!touched.zipCode && !!errors.zipCode}>
              <FormLabel pt="4px" minWidth="100px">
                Zip Code
              </FormLabel>
              <Editable
                value={registrationData.userInformation.zipCode}
                isDisabled
                defaultValue="00901"
                color="gray.500"
              >
                <EditablePreview />
                <EditableInput
                  style={{ boxShadow: "none" }}
                  border="1px"
                  borderColor="gray.300"
                  pl="12px"
                  minWidth="250px"
                />
              </Editable>
            </FormControl>
          </HStack>
          <HStack align="start">
            <FormControl isInvalid={!!touched.city && !!errors.city}>
              <FormLabel pt="4px" minWidth="100px">
                Subscription
              </FormLabel>
              <Editable
                value={planLabel[registrationData.subscriptionType as planType]}
                isDisabled
                color="gray.500"
              >
                <EditablePreview />
                <EditableInput
                  style={{ boxShadow: "none" }}
                  border="1px"
                  borderColor="gray.300"
                  pl="12px"
                  minWidth="250px"
                />
              </Editable>
            </FormControl>
            <FormControl>
              <FormLabel pt="4px" minWidth="100px">
                {registrationData.userInformation.role === "Guardian" &&
                registrationData.subscriptionType === "BASIC"
                  ? ""
                  : "Amount to Be Paid"}
              </FormLabel>
              <Editable
                hidden={
                  plansPrice[registrationData.subscriptionType as planType] ===
                  0
                }
                value={`$ ${
                  plansPrice[registrationData.subscriptionType as planType] !==
                  undefined
                    ? plansPrice[
                        registrationData.subscriptionType as planType
                      ].toString()
                    : "0.00"
                }`}
                isDisabled
                color="gray.500"
              >
                <EditablePreview />
                <EditableInput
                  style={{ boxShadow: "none" }}
                  border="1px"
                  borderColor="gray.300"
                  pl="12px"
                  minWidth="150px"
                />
              </Editable>
            </FormControl>
          </HStack>
          <Box
            display="flex"
            justifyContent={{ base: "space-between", md: "end" }}
            gap={7}
            mt={5}
          >
            <Box display="flex" justifyContent={{ base: "center", md: "end" }}>
              <Button
                onClick={handlePreviousStep}
                _active={{ bg: "custom.white" }}
                _hover={{ bg: "gray.100" }}
                backgroundColor="custom.white"
                color="custom.grayIcon"
                border="1px solid"
                borderColor="custom.grayIcon"
                width={{ base: "130px", md: "150px" }}
                px={8}
              >
                Back
              </Button>
            </Box>
            <Box display="flex" justifyContent={{ base: "center", md: "end" }}>
              <Button
                backgroundColor="custom.blue"
                color="custom.white"
                width={{ base: "130px", md: "150px" }}
                _hover={{ bg: "custom.blue" }}
                px={8}
                onClick={submitForm}
              >
                {isPaidPlan && !isScout ? "Next" : "Submit"}
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default ReviewStep;
