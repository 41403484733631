import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import HomePageProtected from "./pages/HomePageProtected";
import ForgotPassword from "./pages/ForgotPasswordPage";
import SetANewPasswordPage from "./pages/SetANewPasswordPage";
import CreatePlayerWizard from "./pages/CreatePlayerWizard";
import PlayerRegistry from "./pages/PlayersRegistry";
import SearchPlayers from "./pages/SearchPlayers";
import MyRoster from "./pages/MyRoster";
import { UserList } from "./components/common/UserList";
import ScoutAccess from "./pages/ScoutAccess";
import Accounts from "./pages/Accounts";

const AppRoutes: React.FC = () => (
  <Router>
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/registration" element={<RegisterPage />} />
      <Route element={<HomePageProtected />}>
        <Route path="/authenticated" element={<>Authenticated</>} />
        <Route path="/news" element={<>News</>} />
        <Route path="/league" element={<>League</>} />
        <Route path="/team" element={<>Team</>} />
        <Route path="/accounts" element={<Accounts />} />
        <Route path="/scout-access" element={<ScoutAccess />} />
        <Route path="/settings" element={<>Settings</>} />
        <Route path="/players" element={<PlayerRegistry />} />
        <Route path="/search-players" element={<SearchPlayers />} />
        <Route path="/my-roster" element={<MyRoster />} />
        <Route path="/create-player" element={<CreatePlayerWizard />} />
      </Route>
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<SetANewPasswordPage />} />
      <Route path="*" element={<>Not found</>} />
    </Routes>
  </Router>
);

export default AppRoutes;
