import {
  Box,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useBoolean,
} from "@chakra-ui/react";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
interface CustomPassFieldProps extends InputProps {
  isOutline?: boolean;
}
const PasswordInput: React.FC<CustomPassFieldProps> = ({
  isOutline = false,
  ...props
}) => {
  const [isVisible, setIsVisible] = useBoolean();
  const styles = props?.sx;

  return (
    <InputGroup size="md" sx={!isOutline ? styles : {}}>
      <Input type={isVisible ? "text" : "password"} {...props} />
      <InputRightElement>
        <Box onClick={setIsVisible.toggle}>
          {isVisible ? <HiOutlineEye /> : <HiOutlineEyeOff />}
        </Box>
      </InputRightElement>
    </InputGroup>
  );
};

export default PasswordInput;
