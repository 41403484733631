import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { FC, ReactNode } from "react";

interface CustomPopupProps {
  onClose: () => void;
  isOpen: boolean;
  titleText: ReactNode;
  titleDate?: string;
  saveButtonText?: string;
  cancelButtonText?: string;
  bodyElements: ReactNode;
  footerElement: ReactNode;
  size?: object;
}

const CustomPopup: FC<CustomPopupProps> = ({
  onClose,
  isOpen,
  size,
  titleDate,
  footerElement,
  titleText,
  saveButtonText,
  cancelButtonText,
  bodyElements,
}) => {
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      size={size ? size : { base: "xs", sm: "md", md: "2xl" }}
    >
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(3px)" />
      <ModalContent>
        <ModalHeader borderBottom={"1px solid #D0D0E1"}>
          <Flex justifyContent={"space-between"}>
            {titleText}
            <Text color={"#232327B2"} fontSize={18}>
              {titleDate}
            </Text>
          </Flex>
        </ModalHeader>
        <ModalBody>{bodyElements}</ModalBody>
        <ModalFooter pb={6}>{footerElement}</ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CustomPopup;
