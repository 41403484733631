import React from "react";
import ellipsis from "../../assets/test/ellipsis.svg";
import plus from "../../assets/test/plus.svg";
import search1 from "../../assets/test/search-1.svg";
import "./style.css";

interface UserListProps {
  newUser: boolean;
  setNewUser: React.Dispatch<React.SetStateAction<boolean>>;
}
export const UserList = ({
  setNewUser,
  newUser,
}: UserListProps): JSX.Element => {
  const users = [
    {
      id: 54,
      name: "Luis H Joik",
      email: "Luis@gmail.com",
      role: "Manager",
      tier: "Standard",
      address: "Some Address",
      city: "Bayamon",
      state: "Puerto Rico",
      country: "US",
      zip: "00901",
      active: "Active",
    },
    {
      id: 52,
      name: "Luis H Joik",
      email: "Luis@gmail.com",
      role: "Manager",
      tier: "Standard",
      address: "Some Address",
      city: "Bayamon",
      state: "Puerto Rico",
      country: "US",
      zip: "00901",
      active: "Active",
    },
    {
      id: 85,
      name: "Luis H Joik",
      email: "Luis@gmail.com",
      role: "Manager",
      tier: "Standard",
      address: "Some Address",
      city: "Bayamon",
      state: "Puerto Rico",
      country: "US",
      zip: "00901",
      active: "Active",
    },
    {
      id: 47,
      name: "Luis H Joik",
      email: "Luis@gmail.com",
      role: "Manager",
      tier: "Standard",
      address: "Some Address",
      city: "Bayamon",
      state: "Puerto Rico",
      country: "US",
      zip: "00901",
      active: "Active",
    },
    {
      id: 25,
      name: "Luis H Joik",
      email: "Luis@gmail.com",
      role: "Manager",
      tier: "Standard",
      address: "Some Address",
      city: "Bayamon",
      state: "Puerto Rico",
      country: "US",
      zip: "00901",
      active: "Active",
    },
    {
      id: 35,
      name: "Luis H Joik",
      email: "Luis@gmail.com",
      role: "Manager",
      tier: "Standard",
      address: "Some Address",
      city: "Bayamon",
      state: "Puerto Rico",
      country: "US",
      zip: "00901",
      active: "Active",
    },
    {
      id: 88,
      name: "Luis H Joik",
      email: "Luis@gmail.com",
      role: "Manager",
      tier: "Standard",
      address: "Some Address",
      city: "Bayamon",
      state: "Puerto Rico",
      country: "US",
      zip: "00901",
      active: "Active",
    },
    {
      id: 58,
      name: "Luis H Joik",
      email: "Luis@gmail.com",
      role: "Manager",
      tier: "Standard",
      address: "Some Address",
      city: "Bayamon",
      state: "Puerto Rico",
      country: "US",
      zip: "00901",
      active: "Active",
    },
  ];

  return (
    <div className="user-list">
      <div className="container">
        <div className="content">
          <h2 className="section-title">User List</h2>
          <div className="actions">
            <div className="search-bar">
              <img className="search-icon" alt="Search" src={search1} />
              <input type="text" placeholder="Search" />
            </div>
            <div className="filters">
              <button className="filter-button active">All</button>
              <button className="filter-button">Manager</button>
              <button className="filter-button">Customer</button>
            </div>
            <button
              className="add-user-button"
              onClick={() => {
                setNewUser(!newUser);
              }}
            >
              <img className="add-icon" alt="Add" src={plus} />
              Add User
            </button>
            <img className="ellipsis-icon" alt="More" src={ellipsis} />
          </div>

          <div className="table">
            <div className="table-header">
              <span>Id</span>
              <span>Name</span>
              <span>Email</span>
              <span>Role</span>
              <span>Tier</span>
              <span>Address</span>
              <span>City</span>
              <span>State</span>
              <span>Country</span>
              <span>Zip Code</span>
              <span>Active</span>
            </div>
            <div className="table-body">
              {users.map((user) => (
                <div key={user.id} className="table-row">
                  <span>{user.id}</span>
                  <span>{user.name}</span>
                  <span>{user.email}</span>
                  <span>{user.role}</span>
                  <span>{user.tier}</span>
                  <span>{user.address}</span>
                  <span>{user.city}</span>
                  <span>{user.state}</span>
                  <span>{user.country}</span>
                  <span>{user.zip}</span>
                  <span>{user.active}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
