import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Checkbox,
  Divider,
  Heading,
  Text,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { ReactElement } from "react";
import { BlueTickRound } from "../../assets/SVGs/Global";

interface SubscriptionTierCardProps {
  isActive: boolean;
  heading: string;
  description: string;
  icon: ReactElement;
  price: Number;
  planDetails: ReactElement;
  hasButton?: boolean;
  onClick: () => void;
  [x: string]: any;
}

const activeButtonStyles = {
  backgroundColor: "custom.blue",
  color: "custom.white",
  _hover: { bg: "custom.blue" },
};
const buttonStyles = {
  _active: { bg: "custom.white" },
  _hover: { bg: "gray.100" },
  backgroundColor: "custom.white",
  color: "custom.grayIcon",
  border: "1px solid",
  borderColor: "custom.grayIcon",
};

const SubscriptionTierCard: React.FC<SubscriptionTierCardProps> = ({
  isActive,
  heading,
  description,
  icon,
  price,
  planDetails,
  hasButton = true,
  onClick,
  ...props
}) => {
  return (
    <>
      <Card
        w="100%"
        h="100%"
        maxWidth="450px"
        minH="600px"
        border="2px solid"
        backgroundColor={"custom.white"}
        shadow="none"
        borderRadius="12px"
        boxShadow="0px 2px 24.2px 0px #00000014"
        borderColor={isActive ? "custom.blue" : "transparent"}
        {...props}
      >
        <CardBody py="25px" px="30px" display="flex" flexDirection="column">
          <Box
            h="60px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pb={0}
          >
            <Box
              h="23px"
              w="23px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Text
                style={{
                  height: "25px",
                  width: "25px",
                  paddingBottom: "0px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {icon}
              </Text>
            </Box>
            {isActive ? (
              <BlueTickRound />
            ) : (
              <Box
                onClick={onClick}
                cursor="pointer"
                sx={{
                  height: "30px",
                  width: "30px",
                  border: "1px solid black",
                  borderRadius: "50%",
                }}
              ></Box>
            )}
          </Box>
          <Heading
            as="h1"
            fontSize="24px"
            fontWeight={600}
            pb={2}
            color="custom.black"
          >
            {heading}
          </Heading>
          <Text
            fontSize="14px"
            fontWeight="400"
            color={"custom.gray3"}
            mt="20px"
          >
            {description}
          </Text>
          <Divider my="20px" />
          <Box mb="20px">{planDetails}</Box>
          <Box mb="20px" mt="auto">
            {price === 0 ? (
              <>
                <Text fontSize={40} fontWeight="600" color="custom.black">
                  Free
                </Text>
              </>
            ) : (
              <>
                <Text fontSize={40} fontWeight="600" color="custom.black">
                  ${`${price}`}
                </Text>
                <Text color="custom.gray3">per year</Text>
              </>
            )}
          </Box>
        </CardBody>
      </Card>
    </>
  );
};

export default SubscriptionTierCard;
