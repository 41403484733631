// src/constants/options.ts

// Lista de escuelas para el autocomplete de "Current School"
export const schoolOptions: string[] = [
  'Acad. San Joaquín - Adjuntas',
  'El Faro Christian Academy - Adjuntas',
  'Col. San Francisco de Asis - Aguada',
  'Acad. Adv. Del Noroeste - Aguadilla',
  'Acad. Carib Christian - Aguadilla',
  'Frobel Bilingual School - Aguadilla',
  'Corpus Christi - Aguadilla',
  'Liceo Aguadillano - Aguadilla',
  'Paraiso Infantil - Aguadilla',
  'Acad. Casa Montessori - Aibonito',
  'Col. Menonita Betania - Aibonito',
  'Valeriana\'s Bilingual School - Añasco',
  'HABACUC Bilingual Academy - Añasco',
  'MAS Integrated School MASIS - Añasco',
  'Instituto Pre-Vocacional e Industrial de PR - Arecibo',
  'Acad. Adventista Del Norte - Arecibo',
  'Arecibo Baseball Academy - Arecibo',
  'Col. Capitán Correa - Arecibo',
  'Hogar Colegio La Milagrosa - Arecibo',
  'Col. Pentecostal - Arecibo',
  'Col. San Felipe - Arecibo',
  'Col. San Juan Bosco - Arecibo',
  'Robles Christian Academy - Arroyo',
  'Academia Nuestra Señora de Fatima - Barceloneta',
  'Alliance Christian Academy - Barranquitas',
  'Col. San Francisco de Asis - Barranquitas',
  'Col. Santísima Trinidad - Bayamón',
  'Acad. Bayamón Military - Bayamón',
  'Acad. Christian Private - Bayamón',
  'Acad. Claret - Bayamón',
  'Acad. Disc. de Cristo (Int./Sup.) - Bayamón',
  'Acad. Fundación Educ. Cristiana - Bayamón',
  'Acad. Santa Rosa - Bayamón',
  'Athena Bilingual Academy - Bayamón',
  'Col. Calados - Bayamón',
  'Col. Discípulos de Cristo - Bayamón',
  'Col. Lab. CUTB-UPR - Bayamón',
  'Col. Nstra. Sra. Del Rosario - Bayamón',
  'Col. San Agustin - Bayamón',
  'Col. Santiago Apostol - Bayamón',
  'Col. Sto. Tomas de Aquino - Bayamón',
  'Inst. Psicopedagógico - Bayamón',
  'Acad. Adventista - Caguas',
  'Acad. Cristiana Logos - Caguas',
  'Cimatec - Caguas',
  'Col. Bautista Elem. - Caguas',
  'Col. Bautista Sup. - Caguas',
  'Col. Centro Paradis - Caguas',
  'Col. Niños Uniendo al Mundo - Caguas',
  'Col. San Miguel - Caguas',
  'Colegio Católico Notre Dame - Caguas',
  'Hogar Cuna San Cristobal - Caguas',
  'Escuela Montessori San Cristobal - Caguas',
  'Mi Pequeño Rebaño - Camuy',
  'Acad. Bautista Sotera Sánchez - Canóvanas',
  'Col. Nstra. Sra. Del Pilar - Canóvanas',
  'Acad. De Diego - Carolina',
  'Acad. Presbiteriana - Carolina',
  'Col. Carvin Elem. - Carolina',
  'Col. Carvin Sup. - Carolina',
  'Col. Cervantes - Carolina',
  'Col. La Piedad - Carolina',
  'Col. Saint Francis - Carolina',
  'Col. Santa Gema - Carolina',
  'Colegio Maria Auxiliadora - Carolina',
  'Col. Bautista Elem. - Carolina',
  'Col. Bautista Sup. - Carolina',
  'Acad. Adventista - Cayey',
  'Círculo Infantil El Despertar - Cayey',
  'Col. Nstra. Sra. Milagrosa - Cayey',
  'Colegio la Merced - Cayey',
  'Mi Pequeño Paraiso Infantil - Cayey',
  'Col. Héctor Urdaneta - Ceiba',
  'Col. Nstra. Sra. Del Rosario - Ciales',
  'Col. Valvanera - Coamo',
  'Asociación Pro Bienestar de la Familia Comerieña - Comerío',
  'Col. La Sagrada Familia - Corozal',
  'Fajardo Academy - Fajardo',
  'Escuela Evangélica Unida de Fajardo - Fajardo',
  'Col. Sonifel - Fajardo',
  'Carlos Beltrán Baseball Academy - Florida',
  'Fountain Christian Bilingüal School - Guayama',
  'Acad. San Antonio - Guayama',
  'Col. Católico San Antonio - Guayama',
  'Col. Saint Patrick\'s Bilingual School - Guayama',
  'Guamani Private School - Guayama',
  'Col. La Inmaculada Concepción - Guayanilla',
  'Col. San Juan Evangelista - Guaynabo',
  'Colegio San Pedro Martir - Guaynabo',
  'DODEA Child Nutrition Services - Guaynabo',
  'Col. Bautista - Gurabo',
  'Acad. Interamericana de Arecibo - Hatillo',
  'Col. Nstra. Sra. Del Carmen - Hatillo',
  'Acad. Pentecostal - Humacao',
  'Col. Nstra. Sra. Perpetuo Socorro - Humacao',
  'Col. San Benito - Humacao',
  'Acad. Castillo Infantil Bil. - Isabela',
  'Acad. Los Pinos Bilingual - Isabela',
  'Col. Cristiano Montclair - Isabela',
  'Col. Bautista - Juncos',
  'Col. La Luz - Juncos',
  'Acad. Maita Luca Military - Las Piedras',
  'Academia Cristiana Cohelet - Las Piedras',
  'Col. Rubí - Las Piedras',
  'Mi Primera Enseñanza - Las Piedras',
  'Col. Villas de Loiza - Loiza',
  'Kelly\'s School - Luquillo',
  'Acad. Discípulos de Cristo - Manatí',
  'Col. Marista - Manatí',
  'Col. Nstra. Sra. De la Inmaculada - Manatí',
  'Acad. Adventista - Mayagüez',
  'Acad. La Inmaculada Concepción - Mayagüez',
  'Col. San Benito - Mayagüez',
  'CADEI - Moca',
  'Acad. Santa Teresita - Naranjito',
  'Col. San Juan Bautista - Orocovis',
  'Mundo Infantil Bennet - Peñuelas',
  'Academia Cristo Rey - Ponce',
  'Academia Santa María Reina - Ponce',
  'Colegio La Milagrosa - Ponce',
  'Colegio Ponceño - Ponce',
  'Colegio San Judas Tadeo - Ponce',
  'Acad. Adv. Del Sur - Ponce',
  'Col. Sagrado Corazón - Ponce',
  'Col. San Rafael - Quebradillas',
  'Acad. Regional Adventista del Este - Rio Grande',
  'Col. Bautista Rosa de Scharon - Rio Grande',
  'Acad. San Agustin - Sabana Grande',
  'Col. Nstra. Sra. Del Perpetuo Socorro - Salinas',
  'Nova School - Salinas',
  'Centro Artesanias Cotui - San Germán',
  'Academia Bautista - San Juan',
  'Academia Interamericana Metro - San Juan',
  'Academia San Jorge - San Juan',
  'Col. Centro Metodista - San Juan',
  'Col. Inmaculada Concepción - San Juan',
  'Col. La Sagrada Familia - San Juan',
  'Col. San Antonio de Padua - San Juan',
  'Col. San Gabriel - San Juan',
  'Col. San Vicente de Paul - San Juan',
  'Col. Santa Monica - San Juan',
  'San Juan Christian Acad. - San Juan',
  'Acad. Adv. Metropolitana - San Juan',
  'Col. Ntra. Sra. Providencia - San Juan',
  'Acad. Adv. Ramón Rivera - San Sebastián',
  'Acad. San Sebastian Martir - San Sebastián',
  'Acad. Cristiana Yarah - Toa Alta',
  'Acad. Disc. De Cristo - Toa Alta',
  'Acad. Teresa Cancel - Toa Alta',
  'Acad. Christian Nazarene - Toa Baja',
  'Acad. Espiritu Santo - Toa Baja',
  'Col. Lurgrea Central - Toa Baja',
  'Antilles Military Academy - Trujillo Alto',
  'Col. Sta. Maria del Camino - Trujillo Alto',
  'Colegio Santa Cruz Montessori - Trujillo Alto',
  'Acad. Dailén - Utuado',
  'Col. San José - Utuado',
  'Acad. Discípulos de Cristo - Vega Alta',
  'Acad. Adventista - Vega Baja',
  'Acad. Christian Military - Vega Baja',
  'Christian Military Academy - Vega Baja',
  'Col. Ivosai - Vega Baja',
  'Col. Maranatha - Vega Baja',
  'Col. Nstra. Sra. Del Rosario - Vega Baja',
  'Col. Mi Pequeño Edén - Vega Baja',
  'Col. Nstra. Sra. Del Carmen - Villalba',
  'Col. El Edén Paraiso Inf. - Yabucoa',
  'Col. Holy Rosary - Yauco',
  'Acad. Bautista - Yauco',
];

export const playingPositionsOptions: string[] = [
  'P - Pitcher', 
  'C - Catcher', 
  '1B - First Base', 
  '2B - Second Base', 
  '3B - Third Base', 
  'SS - Shortstop', 
  'LF - Left Fielder', 
  'CF - Center Fielder', 
  'RF - Right Fielder', 
  'DH - Designated Hitter', 
  'PH - Pinch Hitter', 
  'PR - Pinch Runner',
];

export const PRCityOptions: string[] = [
  'Adjuntas',
  'Aguada',
  'Aguadilla',
  'Aguas Buenas',
  'Aibonito',
  'Añasco',
  'Arecibo',
  'Arroyo',
  'Barceloneta',
  'Barranquitas',
  'Bayamón',
  'Cabo Rojo',
  'Caguas',
  'Camuy',
  'Canóvanas',
  'Carolina',
  'Cataño',
  'Cayey',
  'Ceiba',
  'Ciales',
  'Cidra',
  'Coamo',
  'Comerio',
  'Corozal',
  'Culebra',
  'Dorado',
  'Fajardo',
  'Florida',
  'Guanica',
  'Guayama',
  'Guayanilla',
  'Guaynabo',
  'Gurabo',
  'Hatillo',
  'Hormigueros',
  'Humacao',
  'Isabela',
  'Jayuya',
  'Juana Díaz',
  'Juncos',
  'Lajas',
  'Lares',
  'Las Marías',
  'Las Piedras',
  'Loíza',
  'Luquillo',
  'Manatí',
  'Maricao',
  'Maunabo',
  'Mayagüez',
  'Moca',
  'Morovis',
  'Naguabo',
  'Naranjito',
  'Orocovis',
  'Patillas',
  'Ponce',
  'Quebradillas',
  'Rincón',
  'Río Grande',
  'Sabana Grande',
  'Salinas',
  'San Germán',
  'San Juan',
  'San Lorenzo',
  'San Sebastián',
  'Santa Isabel',
  'Toa Alta',
  'Toa Baja',
  'Trujillo Alto',
  'Utuado',
  'Vega Alta',
  'Vega Baja',
  'Vieques',
  'Villalba',
  'Yabucoa',
  'Yauco',
];

  // Lista de colegios para "Aiming Colleges"
  export const collegeOptions: string[] = [
      'Harvard University',
      'Stanford University',
      'Massachusetts Institute of Technology (MIT)',
      'California Institute of Technology (Caltech)',
      'University of Chicago',
      'Princeton University',
      'Yale University',
      'Columbia University',
      'University of Pennsylvania',
      'Duke University',
      'University of California, Berkeley',
      'University of Michigan, Ann Arbor',
      'Northwestern University',
      'Cornell University',
      'University of California, Los Angeles (UCLA)',
      'Johns Hopkins University',
      'New York University (NYU)',
      'University of Southern California (USC)',
      'University of Texas at Austin',
      'Carnegie Mellon University',
      'University of Washington',
      'University of Wisconsin-Madison',
      'University of Illinois Urbana-Champaign',
      'University of Florida',
      'Brown University',
      'University of North Carolina at Chapel Hill',
      'Vanderbilt University',
      'Emory University',
      'University of Virginia',
      'Georgetown University',
      'University of Notre Dame',
      'Rice University',
      'Georgia Institute of Technology',
      'Washington University in St. Louis',
      'Boston University',
      'Boston College',
      'Pennsylvania State University (Penn State)',
      'Purdue University',
      'University of California, San Diego (UCSD)',
      'University of California, Davis (UC Davis)',
      'University of California, Santa Barbara (UCSB)',
      'University of California, Irvine (UC Irvine)',
      'University of Miami',
      'University of Maryland, College Park',
      'University of Colorado Boulder',
      'University of Minnesota, Twin Cities',
      'Indiana University Bloomington',
      'Michigan State University',
      'University of Arizona',
      'University of Pittsburgh',
      'University of Massachusetts Amherst',
      'Arizona State University',
      'Rutgers University',
      'University of Connecticut',
      'University of Delaware',
      'University of Georgia',
      'University of South Carolina',
      'University of Alabama',
      'University of Kentucky',
      'University of Tennessee',
      'Auburn University',
      'University of Missouri',
      'University of Nebraska-Lincoln',
      'University of Kansas',
      'University of Oklahoma',
      'Texas A&M University',
      'Baylor University',
      'Southern Methodist University (SMU)',
      'Tulane University',
      'Syracuse University',
      'Wake Forest University',
      'Brandeis University',
      'Dartmouth College',
      'California State University, Fullerton',
      'California State University, Long Beach',
      'University of Hawaii at Manoa',
      'University of Vermont',
      'University of Rhode Island',
      'University of New Hampshire',
      'University of Oregon',
      'Oregon State University',
      'University of Utah',
      'Brigham Young University',
      'University of Nevada, Las Vegas (UNLV)',
      'University of Nevada, Reno',
      'University of New Mexico',
      'University of Wyoming',
      'University of Idaho',
      'Montana State University',
      'University of Montana',
      'Boise State University',
      'University of Alaska Fairbanks',
      'University of South Florida (USF)',
      'Florida State University',
      'Florida International University (FIU)',
      'University of Central Florida (UCF)',
      'Loyola University Chicago',
      'DePaul University',
      'Marquette University',
      'Saint Louis University',
      'Fordham University',
      'Temple University',
      'George Washington University',
      'American University',
      'Pepperdine University',
      'Northeastern University',
      'Tufts University',
      'University of San Diego',
      'Santa Clara University',
      'Villanova University',
      'Rochester Institute of Technology (RIT)',
      'Case Western Reserve University',
      'Clark University',
      'Stevens Institute of Technology',
      'University of Denver',
      'University of San Francisco',
      'Seton Hall University',
      'Loyola Marymount University',
      'Creighton University',
      'Xavier University',
      'Lehigh University',
      'Bentley University',
      'Trinity College',
      'College of William & Mary',
      'Clemson University',
      'University of Mississippi',
      'University of Arkansas',
      'University of Tulsa',
      'Southern Illinois University',
      'University of Dayton',
      'Drake University',
      'Chapman University',
      'University of Redlands',
      'University of Hartford',
      'Quinnipiac University',
      'University of the Pacific',
      'Hofstra University',
      'Mercer University',
      'Samford University',
      'Elon University',
      'Butler University',
      'Bryn Mawr College',
      'Mount Holyoke College',
      'Smith College',
      'Amherst College',
      'Williams College',
      'Swarthmore College',
      'Pomona College',
      'Bowdoin College',
      'Middlebury College',
      'Colby College',
      'Wesleyan University',
      'Carleton College',
      'Davidson College',
      'Haverford College',
      'Grinnell College',
      'Vassar College',
      'Colorado College',
      'Hamilton College',
      'Wellesley College',
      'Oberlin College',
      'Reed College',
      'Bates College',
      'Macalester College',
      'Harvey Mudd College',
      'Claremont McKenna College',
      'Scripps College',
      'Pitzer College',    
  ];
  