import React from 'react';
import {
    Box,
    Container,
    Heading,
    SimpleGrid,
    Text,
    Image,
    Flex,
    Icon,
    List,
} from '@chakra-ui/react';
import { SearchIcon, InfoIcon } from '@chakra-ui/icons';
import { RiFileList3Line } from "react-icons/ri";
import { NavLink } from 'react-router-dom';
import Layout from '../components/common/Layout';

const PlayerRegistry = () => {
    return (
        <>
            <Box width="100%" height = "100%">
                <Container
                    margin={0}  px={6}>
                    <Heading as="h1" size="xl" mb={6}>
                        Players Registry
                    </Heading>
                    <SimpleGrid
                        columns={{ base: 1, md: 2 }}
                        spacing={4}
                        mb={4}
                    >
                        <NavLink to="/search-players">
                            <Box
                                _hover={{
                                    boxShadow: "lg",
                                    transition: "all .3s"
                                }}
                                transition={"all .3s"}
                                cursor="pointer"
                                bg="white"
                                p={6}
                                borderRadius="20px"
                                border="1px solid #D0D0E1"
                            >
                                <Text fontSize="lg" fontWeight="bold" >
                                    Search Players
                                </Text>
                                <Box
                                    display={"flex"}
                                    justifyContent="space-between"
                                    width="100%"
                                    alignItems="center">
                                    <Icon as={SearchIcon} boxSize={10} color={'custom.grayIcon'} />
                                    <Image
                                        alt="Illustration of a baseball player with a bat and helmet"
                                        boxSize="70px"
                                        src="/images/SearchPlayers.png"
                                    />
                                </Box>
                            </Box>
                        </NavLink>
                        <NavLink to="/my-roster">
                            <Box
                                _hover={{
                                    boxShadow: "lg",
                                    transition: "all .3s"
                                }}
                                transition={"all .3s"}
                                cursor="pointer"
                                bg="white"
                                p={6}
                                borderRadius="20px"
                                border="1px solid #D0D0E1"
                            >
                                <Text fontSize="lg" fontWeight="bold" >
                                    My Roster
                                </Text>
                                <Box
                                    display={"flex"}
                                    justifyContent="space-between"
                                    width="100%"
                                    alignItems="center">
                                    <Icon as={RiFileList3Line} boxSize={10} color={'custom.grayIcon'} />
                                    <Image
                                        alt="Illustration of a baseball player with a bat and helmet"
                                        boxSize="70px"
                                        src="/images/MyRoster.png"
                                    />
                                </Box>
                            </Box>
                        </NavLink>
                    </SimpleGrid>
                </Container>
            </Box>
        </>
    );
};

export default PlayerRegistry;