import { RootState } from './../index';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface DataItem {
    id: number,
    createdDate: string;
    createdTime: string;
    name: string;
    telephoneNumber: string;
    Email: string;
    Gender: string;
    BirthDate: string;
    Address: string;
    status: string;
}

interface RequestResponse {
    pendingRequest: DataItem[];
    requestHistory: DataItem[];
}

export const getPendingRequestUserData = createAsyncThunk("request/getPendingRequestUserData",
    async (data: object, { rejectWithValue }) => {
        try {
            // API implementation pending...
            return data
        } catch (error) {
            return rejectWithValue("Network error. Please try again.");
        }
    }
)

export const getRequestHistoryUserData = createAsyncThunk("request/getRequestHistoryUserData",
    async (data: object, { rejectWithValue }) => {
        try {
            // API implementation pending...
            return data
        } catch (error) {
            return rejectWithValue("Network error. Please try again.");
        }
    }
)

export const addDenyResolutionNote = createAsyncThunk("request/addDenyRequest",
    async (data: object, { rejectWithValue }) => {
        try {
            // API implementation pending...
            return data
        } catch (error) {
            return rejectWithValue("Network error. Please try again.");
        }
    }
)

export const approveRequestData = createAsyncThunk<RequestResponse, DataItem, { state: RootState }>(
    "request/approveRequest",
    async (data: DataItem, { getState, rejectWithValue }) => {
        try {
            // API implementation pending...
            const pendingRequest = getState().request.pendingRequestData.filter((item: DataItem) => item.id !== data.id);
            const requestHistory = [...getState().request.requestHistoryData, { ...data, status: 'Approved' }];

            return {
                pendingRequest,
                requestHistory
            };
        } catch (error) {
            return rejectWithValue("Network error. Please try again.");
        }
    }
);

export const denyRequestData = createAsyncThunk<RequestResponse, DataItem, { state: RootState }>(
    "request/denyRequest",
    async (data: DataItem, { getState, rejectWithValue }) => {
        try {
            // API implementation pending...
            const pendingRequest = getState().request.pendingRequestData.filter((item: DataItem) => item.id !== data.id);
            const requestHistory = [...getState().request.requestHistoryData, { ...data, status: 'Denied' }];
            return {
                pendingRequest,
                requestHistory
            };
        } catch (error) {
            return rejectWithValue("Network error. Please try again.");
        }
    }
);

const initialState = {
    pendingRequestData: [],
    requestHistoryData: [],
    isLoading: false
}

const requestSlice = createSlice({
    name: 'request',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // get pending request data
            .addCase(getPendingRequestUserData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPendingRequestUserData.fulfilled, (state, action) => {
                state.pendingRequestData = action.payload as any;
                state.isLoading = false;
            })
            .addCase(getPendingRequestUserData.rejected, (state) => {
                state.isLoading = false;
            })

            // get request history data
            .addCase(getRequestHistoryUserData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getRequestHistoryUserData.fulfilled, (state, action) => {
                state.requestHistoryData = action.payload as any;
                state.isLoading = false;
            })
            .addCase(getRequestHistoryUserData.rejected, (state) => {
                state.isLoading = false;
            })

            // approve request
            .addCase(approveRequestData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(approveRequestData.fulfilled, (state, action) => {
                state.pendingRequestData = action.payload.pendingRequest as any;
                state.requestHistoryData = action.payload.requestHistory as any;
                state.isLoading = false;
            })
            .addCase(approveRequestData.rejected, (state) => {
                state.isLoading = false;
            })

            // add resolution note when deny
            .addCase(addDenyResolutionNote.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addDenyResolutionNote.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(addDenyResolutionNote.rejected, (state) => {
                state.isLoading = false;
            })


            // deny request
            .addCase(denyRequestData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(denyRequestData.fulfilled, (state, action) => {
                state.pendingRequestData = action.payload.pendingRequest as any;
                state.requestHistoryData = action.payload.requestHistory as any;
                state.isLoading = false;
            })
            .addCase(denyRequestData.rejected, (state) => {
                state.isLoading = false;
            })
    }
})

export default requestSlice.reducer