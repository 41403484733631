import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import PropTypes from 'prop-types';
import { FC, ReactNode } from 'react';

interface AlertPopupProps {
  onClose: () => void;
  isOpen: boolean;
  titleText: string;
  description: string;
  footerElements: ReactNode;
  alertIcon: ReactNode;
}

const AlertPopup: FC<AlertPopupProps> = ({ onClose, isOpen, titleText, description, footerElements, alertIcon }) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} size={{ base: 'xs', sm: 'sm', md: 'xl' }} >
      <ModalOverlay
        bg='blackAlpha.300'
        backdropFilter='blur(3px)' />
      <ModalContent alignItems={'center'} minHeight={{ base: 'auto', md: '400px' }} textAlign={'center'} p={8} my={'auto'} gap={{ base: 3, md: 8 }} borderRadius={'xl'}>
        <Box alignItems={'center'} height={{ base: '80px', md: '110px' }} width={{ base: '80px', md: '110px' }} display={'flex'} justifyContent={'center'}>
          {alertIcon}
        </Box>
        <ModalHeader padding={0} margin={0}>
          <Text fontSize={{ base: 'lg', md: '2xl' }}>{titleText}</Text>
        </ModalHeader>
        <ModalCloseButton top={8} right={8} />
        <ModalBody padding={0} margin={0} >
          <Text fontSize={{ base: 'medium', md: 'xl' }}>
            {description}
          </Text>
        </ModalBody>

        <ModalFooter display={'flex'} justifyContent={'center'}>
          {footerElements}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AlertPopup
AlertPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  titleText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  footerElements: PropTypes.element.isRequired,
  alertIcon: PropTypes.element.isRequired
};