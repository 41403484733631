import React from 'react';
import { Box, Text, Center, IconButton, Image } from '@chakra-ui/react';
import { FaEllipsisH } from 'react-icons/fa';

interface PlaceholderCardProps {
  playerName: string;
  playerPositions: string;
  playerID: string;
}

const PlaceholderCard: React.FC<PlaceholderCardProps> = ({ playerName, playerPositions, playerID }) => (
  <Box
    width="338px"
    height="472px"
    border="4px solid #00aaff"
    borderRadius="10px"
    position="relative"
    backgroundColor="transparent"
    overflow="hidden"
    clipPath="polygon(0 0, 100% 0, 100% 85%, 79% 100%, 0 100%)"
  >
    {/* Fondo de color con el logo en la esquina superior izquierda */}
    <Box
      position="absolute"
      top="0px"
      left="0"
      width="35%"
      height="70px"
      backgroundColor="white"
      clipPath="polygon(0 100%, 60% 100%, 100% 0%, 0 0)"
      display="flex"
      alignItems="center"
      pl={2}
    >
      <Image
        src="/images/StatsLogo.png"
        alt="Logo"
        position="absolute"
        top="15px"
        left="15px"
        boxSize="50px"
        zIndex={1}
      />
    </Box>

    {/* Imagen de fondo del jugador */}
    <Image
      src="/images/User Silhoulette.jpg"
      alt="User Silhouette"
      position="absolute"
      top="50%"
      left="50%"
      width="250px"
      height="250px"
      transform="translate(-50%, -50%)"
      objectFit="cover"
      zIndex={-1}
    />

    {/* Trapecio superior para la posición */}
    <Box
      position="absolute"
      bottom="50px"
      left="0"
      width="30%"
      height="50px"
      backgroundColor="#00aaff"
      clipPath="polygon(0 100%, 100% 100%, 60% 0, 0 0)"
      display="flex"
      alignItems="center"
      pl={2}
    >
      <Text color="white" fontWeight="bold" fontSize="16px">
        {playerID}
      </Text>
    </Box>

    {/* Trapecio inferior para el nombre */}
    <Box
      position="absolute"
      bottom="0"
      left="0"
      width="60%"
      height="50px"
      backgroundColor="#09264a"
      clipPath="polygon(0 100%, 100% 100%, 80% 0, 0 0)"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="center"
      pl={2}
    >
      <Text color="white" fontWeight="bold" fontSize="14px">
        {playerName}
      </Text>
      <Text color="white" fontWeight="bold" fontSize="12px">
        {playerPositions}
      </Text>
    </Box>

    {/* Mordida circular en la esquina inferior derecha */}
    <Box
      content=""
      position="absolute"
      bottom="0"
      right="0"
      width="20%"
      height="14.5%"
      backgroundColor="transparent"
      borderRadius="52.5px 0 0 0"
      border="4px solid #00aaff"
      borderBottom="none"
      borderRight="none"
    />
  </Box>
);

export default PlaceholderCard;