import React, { useEffect, useState } from "react";
import {
  Box,
  Step,
  StepIndicator,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  useSteps,
  useBreakpointValue,
} from "@chakra-ui/react";
import { RegistrationStepData } from "../types/pages/registration";
import {
  HiOutlineCalendar,
  HiOutlineCurrencyDollar,
  HiOutlineDocumentSearch,
  HiOutlineMail,
  HiOutlineUser,
} from "react-icons/hi";
import RegistrationStep from "../components/registration/RegistrationStep";
import EmailVerificationStep from "../components/registration/EmailVerificationStep";
import SubscriptionStep from "../components/registration/SubscriptionStep";
import PaymentStep from "../components/registration/PaymentStep";
import ReviewStep from "../components/registration/ReviewStep";
import { Logo } from "../assets/SVGs/Global";

const allSteps: RegistrationStepData[] = [
  {
    title: "Registration",
    icon: <HiOutlineUser />,
  },
  {
    title: "Email Validation",
    icon: <HiOutlineMail />,
  },
  {
    title: "Subscription",
    icon: <HiOutlineCalendar />,
  },
  {
    title: "Review",
    icon: <HiOutlineDocumentSearch />,
  },
  {
    title: "Payment Details",
    icon: <HiOutlineCurrencyDollar />,
  },
];

const RegisterPage: React.FC = () => {
  const [role, setRole] = useState(""); // Track role selection
  const [selectedSubscription, setSelectedSubscription] = useState(""); // Track selected subscription

  // Adjust steps dynamically based on role and subscription tier
  const steps =
    role === "Scout" ||
    (role === "Guardian" && selectedSubscription === "BASIC")
      ? allSteps.slice(0, 4) // Skip Payment Details step for Scout or Guardian with Starter tier
      : allSteps;

  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 0,
    count: steps.length,
  });

  const stepperOrientation = useBreakpointValue<"horizontal" | "vertical">(
    {
      base: "vertical",
      lg: "horizontal",
    },
    {
      fallback: "lg",
    }
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [activeStep]);

  // Function to handle role selection (passed to RegistrationStep component)
  const handleRoleSelection = (selectedRole: string) => {
    setRole(selectedRole);
  };

  // Function to handle subscription selection (passed to SubscriptionStep component)
  const handleSubscriptionSelection = (subscription: string) => {
    setSelectedSubscription(subscription);
  };

  return (
    <Box h="100%" w="100%">
      <Box display="flex" h="100%" minH="100vh" overflow="hidden">
        <Box
          display={{ base: "none", xl: "block" }}
          w="35%"
          h="auto"
          backgroundImage="url('/images/Ragistration_bg.jpg')"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
        >
          <Box
            w="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Logo height={284} width={284} />
          </Box>
        </Box>
        <Box p={8} pb={0} w={{ base: "100%", xl: "65%" }}>
          {/* Stepper container */}
          <Box
            display="flex"
            px={{ base: "0px", md: "50px", lg: "0px" }}
            justifyContent={{ base: "start", lg: "center" }}
          >
            <Stepper
              size="lg"
              index={activeStep}
              colorScheme="blue"
              orientation={stepperOrientation}
              sx={{
                ".chakra-step": {
                  flexBasis: "unset",
                  ".chakra-step__separator": {
                    minWidth: "20px",
                  },
                },
              }}
            >
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepIndicator
                    fontWeight="600"
                    borderColor={index + 1 > activeStep ? "#959595" : "#1B154B"}
                    color={index + 1 > activeStep ? "#959595" : "#ffffff"}
                    sx={{
                      "[data-status=complete] &": {
                        background: "#1B154B",
                        borderColor: "#1B154B",
                        color: "#fff",
                      },
                      "[data-status=active] &": {
                        background: "#1B154B",
                        borderColor: "#1B154B",
                        color: "#fff",
                      },
                    }}
                  >
                    <StepStatus
                      complete={index + 1}
                      incomplete={index + 1}
                      active={index + 1}
                    />
                  </StepIndicator>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      "[data-status=complete] &": {
                        color: "#232327",
                      },
                      "[data-status=active] &": {
                        color: "#1B154B",
                      },
                      "[data-status=incomplete] &": {
                        color: "#959595",
                      },
                    }}
                  >
                    <StepTitle>{step.title}</StepTitle>
                  </Box>
                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
          </Box>

          {/* Stepper content */}
          <Box px={{ base: "0px", md: "50px", lg: "100px" }} py="50px">
            <Box>
              {activeStep === 0 && (
                <RegistrationStep
                  handleNextStep={goToNext}
                  handleRoleSelection={handleRoleSelection} // Pass role selection handler
                />
              )}
              {activeStep === 1 && (
                <EmailVerificationStep
                  handleNextStep={goToNext}
                  handlePreviousStep={goToPrevious}
                />
              )}
              {activeStep === 2 && (
                <SubscriptionStep
                  handleNextStep={goToNext}
                  handlePreviousStep={goToPrevious}
                  handleSubscriptionSelection={handleSubscriptionSelection} // Pass subscription selection handler
                />
              )}
              {activeStep === 3 && (
                <ReviewStep
                  handleNextStep={goToNext}
                  handlePreviousStep={goToPrevious}
                />
              )}
              {activeStep === 4 && (
                <PaymentStep
                  handleNextStep={goToNext}
                  handlePreviousStep={goToPrevious}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RegisterPage;
