import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
// import coffeeReducer from "./coffeeSlice";
import registrationSlice from "./registrationSlice";
import requestSlice from './slices/requestSlice'
import { stripeApi } from "../services/stripeApi"; // Import the API

const store = configureStore({
  reducer: {
    auth: authReducer,
    // coffee: coffeeReducer,
    registration: registrationSlice,
    request: requestSlice,
    [stripeApi.reducerPath]: stripeApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(stripeApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
