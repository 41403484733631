import React, { useState, useEffect } from 'react';
import { PlayerFormProvider } from '../context/PlayerFormContext';
import CreatePlayerStep1 from './CreatePlayerStep1';
import CreatePlayerStep2 from './CreatePlayerStep2';
import CreatePlayerStep3 from './CreatePlayerStep3';
import {
  Box,
  Container,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepTitle,
  StepSeparator,
  useBreakpointValue,
  Flex,
  Text,
  IconButton,
} from '@chakra-ui/react';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const CreatePlayerWizard: React.FC = () => {
  const [activeStep, setActiveStep] = useState(1); 

  const steps = [
    { title: 'New Player' },
    { title: 'Add-Ons' },
    { title: 'Review' },
  ];

  const stepperOrientation = useBreakpointValue<'horizontal' | 'vertical'>({
    base: 'vertical',
    md: 'horizontal',
  });

  const handleNextStep = () => setActiveStep((prev) => prev + 1);
  const handlePreviousStep = () => setActiveStep((prev) => prev - 1);

  const navigate = useNavigate();
  const toMyRoster = () => {
    navigate('/my-roster');
  };

  useEffect(() => {
    if (activeStep < 1) {
      setActiveStep(1);
    } else if (activeStep > steps.length) {
      setActiveStep(steps.length);
    }
  }, [activeStep, steps.length]);

  return (
    <PlayerFormProvider>
      <>
      <Box width="100%" p={2} minHeight="100vh" mx="auto">
          <Container maxW="container.xl" mx="auto" px={0}>
            <Flex mb={6} direction="row" align="center" justify="space-between">
              <Flex align="center" width="250px" >
                {activeStep === 1 && (
                  <IconButton
                    aria-label="Back to My Roster"
                    icon={<FaArrowLeft />}
                    onClick={toMyRoster}
                    bg="white"
                    boxShadow="sm"
                    _hover={{ boxShadow: 'md', bg: 'gray.200' }}
                    mr={2}
                  />
                )}
                <Box>
                  <Text fontSize="3xl" fontWeight="bold">
                    {steps[activeStep - 1].title}
                  </Text>
                </Box>
              </Flex>
              <Box flex="1">
                <Stepper
                  index={activeStep - 1}
                  orientation={stepperOrientation}
                  colorScheme="blue"
                  width="80%"
                  mx="auto"
                  gap={4}
                >
                  {steps.map((step, index) => (
                    <Step key={index}>
                      <StepIndicator
                        boxSize={12}
                        _active={{ bg: 'blue.500', color: 'white' }}
                        _complete={{ bg: 'blue.500', color: 'white' }}
                        _incomplete={{ bg: 'gray.200', color: 'gray.500' }}
                      >
                        {index + 1}
                      </StepIndicator>
                      <Box flexShrink="0">
                        <StepStatus
                          complete={<StepTitle>{step.title}</StepTitle>}
                          incomplete={<StepTitle>{step.title}</StepTitle>}
                          active={<StepTitle>{step.title}</StepTitle>}
                        />
                      </Box>
                      {index !== steps.length - 1 && <StepSeparator />}
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Flex>
            <Box >
              {activeStep === 1 && (
                <CreatePlayerStep1 handleNextStep={handleNextStep} />
              )}
              {activeStep === 2 && (
                <CreatePlayerStep2
                  handleNextStep={handleNextStep}
                  handlePreviousStep={handlePreviousStep}
                />
              )}
              {activeStep === 3 && (
                <CreatePlayerStep3 handlePreviousStep={handlePreviousStep} />
              )}
            </Box>
          </Container>
        </Box>
      </>
    </PlayerFormProvider>
  );
};

export default CreatePlayerWizard;
