import React, { createContext, useContext, ReactNode } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
import { logout as logoutAction } from "../store/authSlice";

interface AuthContextType {
  user: string | null;
  // login: (username: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();

  // const login = (username: string) => dispatch(loginAction(username));
  const logout = () => dispatch(logoutAction());

  return (
    <AuthContext.Provider value={{ user, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
